import { Menu } from './menu.model';


export const superAdminVerticalMenuItems = [
    new Menu(1, 'Owners', '/customer-support/home/owners', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Denominations', '/customer-support/home/denominations', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Flags', '/customer-support/home/flags', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Lookups', '/customer-support/home/lookups', null, 'fa fa-tasks', null, true, 0),

];

export const superAdminHorizontalMenuItems = [

    new Menu(1, 'Owners', '/customer-support/home/owners', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Denominations', '/customer-support/home/denominations', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Flags', '/customer-support/home/flags', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Lookups', '/customer-support/home/lookups', null, 'fa fa-tasks', null, true, 0),

];
