<div class="row" *ngIf="printFormat == 1">
    <div id="invoice-print">
        <div class="header">
            <div class="">
                <img [src]="OwnerDetails.LogoPath" (error)="error()" (load)="loadedImage()" alt="" class="owner-logo" />
            </div>
            <div class="summary"><b>{{OwnerDetails?.OwnerCompanyName}}</b></div>
            <div class="standard-branch-details">
                <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                <div>{{BranchDetails?.BranchPhone}}</div>
            </div>
        </div>
        <div class="main">
            <div class="invoice-box">
                <h3 class="margin-0">{{InvoiceExID}}</h3>
                <!-- <h4 class="margin-0 red">{{'is Transfer' | translate}}: {{isTransfer ? 'Yes' : 'No'}}</h4> -->
                <h4>
                    {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                </h4>
                <h4>
                    {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                </h4>
                <h4>
                    {{'Created Date' | translate}} : {{InvoiceDate}}
                    {{InvoiceTime}}
                </h4>
                <h4 class="margin-0">
                    {{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}
                </h4>
                <table>
                    <tr>
                        <div class="" *ngIf="isEWire">
                            <h4 class="bg-ash margin-0 text-center">
                                <b>{{'Receipient Details' | translate}}</b>
                            </h4>
                            <div *ngFor="let item of Receipient; let i = index">
                                <div class="column">

                                    {{'First Name' | translate}} :
                                    {{item.FirstName}}
                                    <br>
                                    {{'ID' | translate}} :
                                    {{item.LinkExtID}}
                                    <br>
                                    {{'Email' | translate}} :
                                    {{item.Email}}
                                    <br>
                                    {{'Occupation' | translate}} :
                                    {{item.Occupation}}
                                    <br>
                                    {{'Nationality' | translate}} :
                                    {{item.Nationality}}
                                    <br>
                                    {{'Address' | translate}} : {{item.Address}}
                                    <br>
                                    {{'City' | translate}} : {{item.City}}
                                    <br>
                                    {{'State' | translate}} : {{item.State}}
                                    <br>

                                </div>
                                <div class="column">
                                    {{'Last Name' | translate}} : {{item.LastName}}
                                    <br>
                                    {{'DOB' | translate}} : {{item.DateOfBirth}}
                                    <br>
                                    {{'Contact Number' | translate}} :
                                    {{item.ContactNumber}}
                                    <br>
                                    {{'Document ID' | translate}} :
                                    {{item.PictureIDNo}}
                                    <br>
                                    {{'Document Type' | translate}} :
                                    {{item.PictureIDType}}
                                    <br>
                                    {{'Date of Issue' | translate}} :
                                    {{item.PictureIDIssue}}
                                    <br>
                                    {{'Expiry Date' | translate}} :
                                    {{item.PictureIDExpiry}}
                                    <br>
                                    {{'Country Of Issue' | translate}} :
                                    {{item.CountryOfIssue}}

                                </div>
                            </div>

                        </div>
                    </tr>
                </table>
                <h3 class="bg-ash margin-0 m-t-10 m-b-10 text-center">
                    <b>{{'Invoice Details' | translate}}</b>
                </h3>
                <table *ngFor="let item of Rows; let i = index" [ngClass]="{'border-top' : i != 0}">
                    <tr>
                        <td class="col-sm-3">{{item.AccountName}}</td>
                        <td class="col-sm-6" colspan="2"><b [innerHtml]="fnComment(item.PreCommentLocale)"></b></td>
                        <td class="col-sm-3" rowspan="2">
                            <img [src]="item.CurrencyFlagPath" alt="" class="flag pull-right" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Amount' | translate}} : {{fnReceivedAmountString(item)}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Rate' | translate}} : {{item.TradingRate|
                            numberFormat}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Inverse Rate' | translate}} :
                            {{item.InverseTradingRate| numberFormat}}</td>
                    </tr>
                    <tr>
                        <td *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </td>
                        <td *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="item.ReferenceNo">{{'Reference' | translate}} : {{item.ReferenceNo}}</td>
                        <td *ngIf="item.isCrypto">{{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}} </td>
                    </tr>

                    <div class="" *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                        <h4 class="bg-ash margin-0 text-center">
                            <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                        </h4>
                        <div [innerHtml]="RowDetailsInDepth(item)">
                        </div>
                    </div>
                </table>
                <div class="border-top"></div>
                <h3 *ngIf="Remarks">
                    {{'Remarks' | translate}} : <span [innerHTML]="Remarks"></span>
                </h3>
                <h3 *ngIf="PurposeOfTransaction">
                    {{'Purpose of Transaction' | translate}} : {{PurposeOfTransaction}}
                </h3>
                <h3 *ngIf="SourceOfFunds">
                    {{'Source Of Funds' | translate}} : {{SourceOfFunds}}
                </h3>
                <div class="border-top" *ngIf="Remarks || PurposeOfTransaction || SourceOfFunds"></div>
                <div class="m-t-10">
                    {{DisclaimerText}}
                </div>

            </div>
        </div>
        <!-- <footer>
            <br>
            <div class="w-100 text-secondary text-center">
                <small>{{'Powered by CXR Cloud' | translate}}</small>
                &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
            </div>
        </footer> -->
    </div>
</div>

<div class="row" *ngIf="printFormat == 2">
    <div id="invoice-print">
        <div class="header">
            <div class="">
                <img [src]="OwnerDetails.LogoPath" (error)="error()" (load)="loadedImage()" alt="" class="owner-logo" />
            </div>
            <div class="summary"><b>{{OwnerDetails?.OwnerCompanyName}}</b></div>
            <div class="standard-branch-details">
                <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                <div>{{BranchDetails?.BranchPhone}}</div>
            </div>
        </div>
        <div class="main">
            <div class="invoice-box">
                <h3 class="margin-0">{{InvoiceExID}} <small class="pull-right">{{InvoiceDate}}
                        {{InvoiceTime}}</small></h3>
                <!-- <h4 class="margin-0 res">{{'is Transfer' | translate}} : {{isTransfer ? 'Yes' : 'No'}}</h4> -->
                <h4>
                    {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                </h4>
                <h4>
                    {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                </h4>
                <h4>
                    {{'Created Date' | translate}} : {{InvoiceDate}}
                    {{InvoiceTime}}
                </h4>
                <h4 class="bg-ash margin-0 text-center">
                    <b>{{'Customer Details' | translate}}</b>
                </h4>
                <table>
                    <tr>
                        <div class="">
                            <div class="column">
                                <td>
                                    {{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}
                                    <br>
                                    {{'ID' | translate}} : {{CustomerDetails?.CustomerID}}
                                    <br>
                                    {{'Email' | translate}} :{{CustomerDetails?.CustomerEmail}}
                                    <br>
                                    {{'Occupation' | translate}} : {{CustomerDetails?.Occupation}}
                                    <br>
                                    {{'Nationality' | translate}} :{{CustomerDetails?.Nationality}}
                                    <br>
                                    {{'Address' | translate}} : {{CustomerDetails?.Address}}
                                    <br>
                                    {{'City' | translate}} : {{CustomerDetails?.City}}
                                    <br>
                                    {{'State' | translate}} : {{CustomerDetails?.State}}
                                    <br>
                                    {{'Zip Code' | translate}} : {{CustomerDetails?.ZipCode}}
                                </td>
                            </div>
                            <div class="column">
                                <td>
                                    {{'DOB' | translate}} : {{CustomerDetails?.DateOfBirth}}
                                    <br>
                                    {{'Contact Number' | translate}} :{{CustomerDetails?.CustomerPhone}}
                                    <br>
                                    {{'Coutry' | translate}} :{{CustomerDetails?.AddressCountry}}
                                    <br>
                                    {{'Document ID' | translate}} : {{PictureID?.PictureIDNumber}}
                                    <br>
                                    {{'Document Type' | translate}} : {{PictureID?.PictureIDType}}
                                    <br>
                                    {{'Date Of Issue' | translate}} : {{PictureID?.IssueDate}}
                                    <br>
                                    {{'Expiry Date' | translate}} : {{PictureID?.ExpiryDate}}
                                    <br>
                                    {{'Country Of Issue' | translate}} : {{PictureID?.CountryOfIssue}}
                                </td>
                            </div>
                        </div>
                    </tr>
                    <!-- RECEIPIENT DETIALS FROM HERE / EWIRE DETAILS -->
                    <tr>

                        <div class="" *ngIf="isEWire">
                            <h4 class="bg-ash margin-0 text-center">
                                <b>{{'Receipient Details' | translate}}</b>
                            </h4>
                            <div *ngFor="let item of Receipient; let i = index">
                                <div class="column">
                                    <td>
                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>
                                    </td>


                                </div>
                                <div class="column">
                                    {{'Last Name' | translate}} : {{item.LastName}}
                                    <br>
                                    {{'DOB' | translate}} : {{item.DateOfBirth}}
                                    <br>
                                    {{'Contact Number' | translate}} :
                                    {{item.ContactNumber}}
                                    <br>
                                    {{'Document ID' | translate}} :
                                    {{item.PictureIDNo}}
                                    <br>
                                    {{'Document Type' | translate}} :
                                    {{item.PictureIDType}}
                                    <br>
                                    {{'Date of Issue' | translate}} :
                                    {{item.PictureIDIssue}}
                                    <br>
                                    {{'Expiry Date' | translate}} :
                                    {{item.PictureIDExpiry}}
                                    <br>
                                    {{'Country Of Issue' | translate}} :
                                    {{item.CountryOfIssue}}

                                </div>
                            </div>

                        </div>
                    </tr>

                </table>

                <h3 class="bg-ash text-center margin-0 m-b-10 ">
                    <b>{{'Invoice Details' | translate}}</b>
                </h3>
                <table *ngFor="let item of Rows; let i = index" [ngClass]="{'border-top' : i != 0}">
                    <tr>
                        <td class="col-sm-3">{{item.AccountName}}</td>
                        <td class="col-sm-6" colspan="2"><b [innerHtml]="fnComment(item.PreComment)"></b></td>
                        <td class="col-sm-3" rowspan="2">
                            <img [src]="item.CurrencyFlagPath" alt="" class="flag pull-right" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Amount' | translate}} : {{fnReceivedAmountString(item)}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Rate' | translate}} : {{item.TradingRate |
                            numberFormat}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Inverse Rate' | translate}} :
                            {{item.InverseTradingRate | numberFormat}}</td>
                    </tr>
                    <tr>
                        <td *ngIf="item.SendEWireFormData?.BeneficiaryDetails.LinkExtID">{{'Link Id' | translate}} :
                            {{item.SendEWireFormData?.BeneficiaryDetails.LinkExtID}}</td>

                    </tr>

                    <tr>
                        <td *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </td>
                        <td *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="item.ReferenceNo">{{'Reference' | translate}} : {{item.ReferenceNo}}</td>
                        <td *ngIf="item.isCrypto">{{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}} </td>
                    </tr>

                    <tr>
                        <td colspan="6">
                            <!-- CHEQUE DETAILS  -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETAILS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="column">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="column">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class=""
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0 text-center">
                                    <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </td>
                    </tr>

                </table>
                <div class="border-top"></div>
                <h4 *ngIf="Remarks">
                    {{'Remarks' | translate}} : <span [innerHTML]="Remarks"></span>
                </h4>
                <h4 *ngIf="PurposeOfTransaction">
                    {{'Purpose of Transaction' | translate}} : {{PurposeOfTransaction}}
                </h4>
                <h4 *ngIf="SourceOfFunds">
                    {{'Source Of Funds' | translate}} : {{SourceOfFunds}}
                </h4>
                <div class="border-top" *ngIf="Remarks || PurposeOfTransaction || SourceOfFunds"></div>
                <div class="m-t-10">
                    {{DisclaimerText}}
                </div>
            </div>
        </div>
        <!-- <footer>
            <br>
            <div class="w-100 text-secondary text-center">
                <small>{{'Powered by CXR Cloud' | translate}}</small>
                &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
            </div>
        </footer> -->
    </div>
</div>

<div class="row" *ngIf="printFormat == 3">
    <div class="w-450 margin-auto">
        <div class="row m-l-10 m-r-10" id="invoice-print">
            <div class="m-b-20">
                <div class="logo">
                </div>
                <div class="col-xs-12 f-z-30 text-center">
                    <b>{{OwnerDetails?.OwnerCompanyName}}</b>
                </div>
                <div class="col-xs-12 branch-details">
                    <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                    <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                    <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                    <div>{{BranchDetails?.BranchPhone}}</div>
                </div>
            </div>
            <div class="main">
                <div class="border-top">
                    <!-- <h4 class="margin-0 m-t-10">{{InvoiceExID}} <small class="pull-right">{{InvoiceDate}}
                        </small></h4> -->
                    <h5 class="margin-0 red">
                        <small class="pull-right">{{InvoiceTime}}</small>
                    </h5>
                    <h4>
                        {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                    </h4>
                    <h4>
                        {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                    </h4>
                    <h4>
                        {{'Created Date' | translate}} : {{InvoiceDate}}
                        {{InvoiceTime}}
                    </h4>
                    <h4 class="margin-0">{{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}</h4>
                    <table style="width: 100%;">
                        <tr>
                            <div class="" *ngIf="isEWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Receipient Details' | translate}}</b>
                                </h4>
                                <div *ngFor="let item of Receipient; let i = index">
                                    <div class="column">

                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>

                                    </div>
                                    <div class="column">
                                        {{'Last Name' | translate}} : {{item.LastName}}
                                        <br>
                                        {{'DOB' | translate}} : {{item.DateOfBirth}}
                                        <br>
                                        {{'Contact Number' | translate}} :
                                        {{item.ContactNumber}}
                                        <br>
                                        {{'Document ID' | translate}} :
                                        {{item.PictureIDNo}}
                                        <br>
                                        {{'Document Type' | translate}} :
                                        {{item.PictureIDType}}
                                        <br>
                                        {{'Date of Issue' | translate}} :
                                        {{item.PictureIDIssue}}
                                        <br>
                                        {{'Expiry Date' | translate}} :
                                        {{item.PictureIDExpiry}}
                                        <br>
                                        {{'Country Of Issue' | translate}} :
                                        {{item.CountryOfIssue}}

                                    </div>
                                </div>

                            </div>
                        </tr>
                    </table>
                    <h4 class="bg-ash m-t-10 text-center">
                        <b>{{'Invoice Details' | translate}}</b>
                    </h4>
                    <div class="row" *ngFor="let item of Rows; let i = index">
                        <div class="col-xs-8 m-t-10">
                            <b [innerHtml]="fnComment(item.PreComment)"></b>
                        </div>
                        <div class="col-xs-4 m-t-10">
                            {{fnReceivedAmountString(item)}}
                        </div>
                        <div class="col-xs-3 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Rate' | translate}} : {{item.TradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-5 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Inverse Rate'}}: {{item.InverseTradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="!item.isReceived">
                            {{fnLocalValue(item)}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.ReferenceNo">
                            {{'Reference' | translate}} : {{item.ReferenceNo}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.isCrypto">
                            {{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}
                        </div>
                        <div class="col-xs-12 m-t-10">
                            <!-- CHEQUE DETALS -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETALS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="column">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="column">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class=""
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0 text-center">
                                    <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="border-top"></div>
                        </div>
                    </div>
                    <div class="m-t-10">
                        {{DisclaimerText}}
                    </div>
                </div>
            </div>
            <!-- <footer>
                <br>
                <div class="w-100 text-secondary text-center">
                    <small>{{'Powered by CXR Cloud' | translate}}</small>
                    &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
                </div>
            </footer> -->
        </div>
    </div>

</div>

<div class="row" *ngIf="printFormat == 4">
    <div class="w-450 margin-auto">
        <div class="row m-r-10 m-l-10" id="invoice-print">
            <div class="m-b-20">
                <div class="col-xs-12 text-center f-z-30">
                    <img [src]="OwnerDetails.LogoPath" (load)="loadedImage()" alt="" style=" float: left;
                    height: 60px;
                    width: auto;
                    margin-top: 5px;" />
                    <b>{{OwnerDetails?.OwnerCompanyName}}</b>
                </div>
                <div class="col-xs-12" class="branch-details">
                    <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                    <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                    <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                    <div>{{BranchDetails?.BranchPhone}}</div>
                </div>
            </div>
            <div class=" main">
                <div class="" class="border-top">
                    <!-- <h4 class="margin-0 m-t-10">{{InvoiceExID}} <small class="pull-right">{{InvoiceDate}}
                        </small></h4> -->
                    <!-- <h5 class="margin-0 red">{{'is Transfer' | translate}} : {{isTransfer ? 'Yes' : 'No'}}
                        <small class="pull-right">{{InvoiceTime}}</small>
                    </h5> -->
                    <h4>
                        {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                    </h4>
                    <h4>
                        {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                    </h4>
                    <h4>
                        {{'Created Date' | translate}} : {{InvoiceDate}}
                        {{InvoiceTime}}
                    </h4>
                    <h4 class="margin-0">{{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}</h4>
                    <table style="width: 100%;">
                        <tr>
                            <div class="" *ngIf="isEWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Receipient Details' | translate}}</b>
                                </h4>
                                <div *ngFor="let item of Receipient; let i = index">
                                    <div class="column">

                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>

                                    </div>
                                    <div class="column">
                                        {{'Last Name' | translate}} : {{item.LastName}}
                                        <br>
                                        {{'DOB' | translate}} : {{item.DateOfBirth}}
                                        <br>
                                        {{'Contact Number' | translate}} :
                                        {{item.ContactNumber}}
                                        <br>
                                        {{'Document ID' | translate}} :
                                        {{item.PictureIDNo}}
                                        <br>
                                        {{'Document Type' | translate}} :
                                        {{item.PictureIDType}}
                                        <br>
                                        {{'Date of Issue' | translate}} :
                                        {{item.PictureIDIssue}}
                                        <br>
                                        {{'Expiry Date' | translate}} :
                                        {{item.PictureIDExpiry}}
                                        <br>
                                        {{'Country Of Issue' | translate}} :
                                        {{item.CountryOfIssue}}

                                    </div>
                                </div>
                            </div>
                        </tr>
                    </table>
                    <h4 class="bg-ash m-t-10 text-center">
                        <b>{{'Invoice Details' | translate}}</b>
                    </h4>
                    <div class="row" *ngFor="let item of Rows; let i = index">
                        <div class="col-xs-8 m-t-10">
                            <b [innerHtml]="fnComment(item.PreComment)"></b>
                        </div>
                        <div class="col-xs-4 m-t-10">
                            {{fnReceivedAmountString(item)}}
                        </div>
                        <div class="col-xs-3 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Rate' | translate}} : {{item.TradingRate | numberFormat}}
                        </div>

                        <div class="col-xs-5 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Inverse Rate' | translate}} : {{item.InverseTradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="!item.isReceived">
                            {{fnLocalValue(item)}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.ReferenceNo">
                            {{'Reference' | translate}} : {{item.ReferenceNo}}
                        </div>
                        <div class="col-xs-4 m-t-10" *ngIf="item.isCrypto">
                            {{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}
                        </div>
                        <div class="col-xs-12 m-t-10">
                            <!-- CHEQUE DETAILS -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETAILS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="column">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="column">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class=""
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0 text-center">
                                    <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="border-top"></div>
                        </div>
                    </div>
                    <div class="m-t-10">
                        {{DisclaimerText}}
                    </div>
                </div>
            </div>
            <!-- <footer>
                <br>
                <div class="w-100 text-secondary" class="text-center">
                    <small>{{'Powered by CXR Cloud' | translate}}</small>
                    &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
                </div>
            </footer> -->
        </div>
    </div>
</div>

<div class="row" *ngIf="printFormat == 5">
    <div id="invoice-print">
        <div class="header">
            <td class="">
                <img [src]="OwnerDetails.LogoPath" (load)="loadedImage()" alt="" class="owner-logo" />
            </td>
            <div class="summary"><b>{{OwnerDetails?.OwnerCompanyName}}</b></div>
            <div class="standard-branch-details">
                <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                <div>{{BranchDetails?.BranchPhone}}</div>
            </div>
        </div>
        <div class="main">
            <div class="invoice-box">
                <h3 class="margin-0">{{InvoiceExID}} <small class="pull-right">{{InvoiceDate}}
                        {{InvoiceTime}}</small></h3>
                <!-- <h4 class="margin-0 res">{{'is Transfer' | translate}} : {{isTransfer ? 'Yes' : 'No'}}</h4> -->
                <h4>
                    {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                </h4>
                <h4>
                    {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                </h4>
                <h4>
                    {{'Created Date' | translate}} : {{InvoiceDate}}
                    {{InvoiceTime}}
                </h4>
                <h4 class="bg-ash margin-0 text-center">
                    <b>{{'Customer Details' | translate}}</b>
                </h4>
                <table style="width: 100%;">
                    <tr>
                        <div class="">
                            <div class="column">
                                <td>
                                    {{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}
                                    <br>
                                    {{'ID' | translate}} : {{CustomerDetails?.CustomerID}}
                                    <br>
                                    {{'Email' | translate}} :{{CustomerDetails?.CustomerEmail}}
                                    <br>
                                    {{'Occupation' | translate}} : {{CustomerDetails?.Occupation}}
                                    <br>
                                    {{'Nationality' | translate}} :{{CustomerDetails?.Nationality}}
                                    <br>
                                    {{'Address' | translate}} : {{CustomerDetails?.Address}}
                                    <br>
                                    {{'City' | translate}} : {{CustomerDetails?.City}}
                                    <br>
                                    {{'State' | translate}} : {{CustomerDetails?.State}}
                                    <br>
                                    {{'Zip Code' | translate}} : {{CustomerDetails?.ZipCode}}
                                </td>
                            </div>
                            <div class="column">
                                <td>
                                    {{'DOB' | translate}} : {{CustomerDetails?.DateOfBirth}}
                                    <br>
                                    {{'Contact Number' | translate}} :{{CustomerDetails?.CustomerPhone}}
                                    <br>
                                    {{'Coutry' | translate}} :{{CustomerDetails?.AddressCountry}}
                                    <br>
                                    {{'Document ID' | translate}} : {{PictureID?.PictureIDNumber}}
                                    <br>
                                    {{'Document Type' | translate}} : {{PictureID?.PictureIDType}}
                                    <br>
                                    {{'Date Of Issue' | translate}} : {{PictureID?.IssueDate}}
                                    <br>
                                    {{'Expiry Date' | translate}} : {{PictureID?.ExpiryDate}}
                                    <br>
                                    {{'Country Of Issue' | translate}} : {{PictureID?.CountryOfIssue}}
                                </td>
                            </div>
                        </div>
                    </tr>
                    <!-- RECEIPIENT DETIALS FROM HERE / EWIRE DETAILS -->
                    <tr>

                        <div class="" *ngIf="isEWire">
                            <h4 class="bg-ash margin-0 text-center">
                                <b>{{'Receipient Details' | translate}}</b>
                            </h4>
                            <div *ngFor="let item of Receipient; let i = index">
                                <div class="column">
                                    <td>
                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>
                                    </td>


                                </div>
                                <div class="column">
                                    {{'Last Name' | translate}} : {{item.LastName}}
                                    <br>
                                    {{'DOB' | translate}} : {{item.DateOfBirth}}
                                    <br>
                                    {{'Contact Number' | translate}} :
                                    {{item.ContactNumber}}
                                    <br>
                                    {{'Document ID' | translate}} :
                                    {{item.PictureIDNo}}
                                    <br>
                                    {{'Document Type' | translate}} :
                                    {{item.PictureIDType}}
                                    <br>
                                    {{'Date of Issue' | translate}} :
                                    {{item.PictureIDIssue}}
                                    <br>
                                    {{'Expiry Date' | translate}} :
                                    {{item.PictureIDExpiry}}
                                    <br>
                                    {{'Country Of Issue' | translate}} :
                                    {{item.CountryOfIssue}}

                                </div>
                            </div>

                        </div>
                    </tr>

                </table>

                <h3 class="bg-ash text-center margin-0 m-b-10 ">
                    <b>{{'Invoice Details' | translate}}</b>
                </h3>
                <table *ngFor="let item of Rows; let i = index" [ngClass]="{'border-top' : i != 0}">
                    <tr>
                        <td class="col-sm-3">{{item.AccountName}}</td>
                        <td class="col-sm-6" colspan="2"><b [innerHtml]="fnComment(item.PreComment)"></b></td>
                        <td class="col-sm-3" rowspan="2">
                            <img [src]="item.CurrencyFlagPath" alt="" class="flag pull-right" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Amount' | translate}} : {{fnReceivedAmountString(item)}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Rate' | translate}} : {{item.TradingRate |
                            numberFormat}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Inverse Rate' | translate}} :
                            {{item.InverseTradingRate | numberFormat}}</td>
                    </tr>
                    <tr>
                        <td *ngIf="item.SendEWireFormData?.BeneficiaryDetails.LinkExtID">{{'Link Id' | translate}} :
                            {{item.SendEWireFormData?.BeneficiaryDetails.LinkExtID}}</td>

                    </tr>

                    <tr>
                        <td *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </td>
                        <td *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </td>
                        <td>
                            {{fnLocalValue(item)}}
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="item.ReferenceNo">{{'Reference' | translate}} : {{item.ReferenceNo}}</td>
                        <td *ngIf="item.isCrypto">{{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}</td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <!-- CHEQUE DETAILS  -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETAILS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="column">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="column">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class=""
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0 text-center">
                                    <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="border-top"></div>
                <h4 *ngIf="Remarks">
                    {{'Remarks' | translate}} : <span [innerHTML]="Remarks"></span>
                </h4>
                <h4 *ngIf="PurposeOfTransaction">
                    {{'Purpose of Transaction' | translate}} : {{PurposeOfTransaction}}
                </h4>
                <h4 *ngIf="SourceOfFunds">
                    {{'Source Of Funds' | translate}} : {{SourceOfFunds}}
                </h4>
                <div class="border-top" *ngIf="Remarks || PurposeOfTransaction || SourceOfFunds"></div>
                <div class="m-t-10">
                    {{DisclaimerText}}
                </div>
            </div>
        </div>
        <!-- <footer>
            <br>
            <div class="w-100 text-secondary text-center">
                <small>{{'Powered by CXR Cloud' | translate}}</small>
                &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
            </div>
        </footer> -->
    </div>
</div>

<div class="row" *ngIf="printFormat == 6">
    <div class="w-400 margin-auto">
        <div class="row" id="invoice-print">
            <div class="m-b-20">
                <div class="col-xs-12 f-z-20 text-center">
                    <b>{{OwnerDetails?.OwnerCompanyName}}</b>
                </div>
                <div class="col-xs-12 branch-details">
                    <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                    <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                    <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                    <div>{{BranchDetails?.BranchPhone}}</div>
                </div>
            </div>
            <div class="main">
                <div class="border-top">
                    <h4 class="margin-0 m-t-10">{{InvoiceExID}} <small class="pull-right">
                        </small></h4>
                    <!-- <h5 class="margin-0 red">
                        <small class="pull-right">{{InvoiceTime}}</small>
                    </h5> -->
                    <h5>
                        {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                    </h5>
                    <h5>
                        {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                    </h5>
                    <h5>
                        {{'Created Date' | translate}} : {{InvoiceDate}}
                        {{InvoiceTime}}
                    </h5>
                    <h5 class="margin-0">{{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}</h5>
                    <table style="width: 100%;">
                        <tr>
                            <div class="" *ngIf="isEWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Receipient Details' | translate}}</b>
                                </h4>
                                <div *ngFor="let item of Receipient; let i = index">
                                    <div class="column">

                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>

                                    </div>
                                    <div class="column">
                                        {{'Last Name' | translate}} : {{item.LastName}}
                                        <br>
                                        {{'DOB' | translate}} : {{item.DateOfBirth}}
                                        <br>
                                        {{'Contact Number' | translate}} :
                                        {{item.ContactNumber}}
                                        <br>
                                        {{'Document ID' | translate}} :
                                        {{item.PictureIDNo}}
                                        <br>
                                        {{'Document Type' | translate}} :
                                        {{item.PictureIDType}}
                                        <br>
                                        {{'Date of Issue' | translate}} :
                                        {{item.PictureIDIssue}}
                                        <br>
                                        {{'Expiry Date' | translate}} :
                                        {{item.PictureIDExpiry}}
                                        <br>
                                        {{'Country Of Issue' | translate}} :
                                        {{item.CountryOfIssue}}

                                    </div>
                                </div>
                            </div>
                        </tr>
                    </table>
                    <h4 class="bg-ash m-t-10 text-center">
                        <b>{{'Invoice Details' | translate}}</b>
                    </h4>
                    <div class="row" *ngFor="let item of Rows; let i = index">
                        <div class="col-xs-8 m-t-10">
                            <b [innerHtml]="fnComment(item.PreComment)"></b>
                        </div>
                        <div class="col-xs-4 m-t-10">
                            {{fnReceivedAmountString(item)}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Rate' | translate}} : {{item.TradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Inverse Rate'}}: {{item.InverseTradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="!item.isReceived">
                            {{fnLocalValue(item)}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="item.ReferenceNo">
                            {{'Reference' | translate}} : {{item.ReferenceNo}}
                        </div>
                        <div class="col-xs-6 m-t-10" *ngIf="item.isCrypto">
                            {{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}
                        </div>
                        <div class="col-xs-12 m-t-10">
                            <!-- CHEQUE DETALS -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETALS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="col-xs-12">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="col-xs-12">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class=""
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0 text-center">
                                    <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="border-top"></div>
                        </div>
                    </div>
                    <div class="m-t-10">
                        {{DisclaimerText}}
                    </div>
                </div>
            </div>
            <!-- <footer>
                <br>
                <div class="w-100 text-secondary text-center">
                    <small>{{'Powered by CXR Cloud' | translate}}</small>
                    <img class="cxr-logo margin-auto" [src]=" cxrLogoPath">
                </div>
            </footer> -->
        </div>
    </div>
</div>

<div class="row m-r-10 m-l-10 m-l-20" *ngIf="printFormat == 7">
    <div class="">
        <div class="row" id="invoice-print">
            <div class="m-b-20">
                <div></div>
                <div class="col-xs-4  f-z-30 m-t-10">
                    <img [src]="OwnerDetails.LogoPath" (load)="loadedImage()" alt="" style=" float: left;
                    height: 60px;
                    width: auto;
                    margin-top: 20x;
                    margin-bottom: 20px;
                    margin-right: 50px;" />
                </div>
                <div class="col-xs-4"> <b>{{OwnerDetails?.OwnerCompanyName}}</b></div>
                <div class="col-xs-4">
                    {{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}
                    {{BranchDetails?.Address}} {{BranchDetails?.City}}
                    {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}} {{BranchDetails?.BranchPhone}}

                </div>
            </div>
            <div class=" main">
                <div class="" class="border-top">
                    <div class="col-xs-12 m-t-b-50 " style="margin-left: -12px;">
                        <h4> {{'Transaction number' | translate}} : {{InvoiceExID}}</h4>
                        <h4> {{'Transaction date' | translate}} : {{InvoiceDate}}</h4>
                        <h4> {{'Branch' | translate}} : {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</h4>
                        <h4> {{'Served by' | translate}} : {{UserName}}</h4>
                        <h4> {{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}</h4>
                    </div>
                    <table style="width: 100%;">
                        <tr>
                            <div class="" *ngIf="isEWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Receipient Details' | translate}}</b>
                                </h4>
                                <div *ngFor="let item of Receipient; let i = index">
                                    <div class="column">

                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>

                                    </div>
                                    <div class="column">
                                        {{'Last Name' | translate}} : {{item.LastName}}
                                        <br>
                                        {{'DOB' | translate}} : {{item.DateOfBirth}}
                                        <br>
                                        {{'Contact Number' | translate}} :
                                        {{item.ContactNumber}}
                                        <br>
                                        {{'Document ID' | translate}} :
                                        {{item.PictureIDNo}}
                                        <br>
                                        {{'Document Type' | translate}} :
                                        {{item.PictureIDType}}
                                        <br>
                                        {{'Date of Issue' | translate}} :
                                        {{item.PictureIDIssue}}
                                        <br>
                                        {{'Expiry Date' | translate}} :
                                        {{item.PictureIDExpiry}}
                                        <br>
                                        {{'Country Of Issue' | translate}} :
                                        {{item.CountryOfIssue}}

                                    </div>
                                </div>
                            </div>
                        </tr>
                    </table>
                    <h3 class="m-t-10">
                        <b>{{'Transaction Details' | translate}}</b>
                    </h3>

                    <div class="row " *ngFor="let item of Rows; let i = index">
                        <div class="col-xs-8 m-t-10">
                            <b *ngIf="item.isReceived==true"> BUY &nbsp;&nbsp;&nbsp;</b>
                            <b *ngIf="item.isReceived==false"> SELL &nbsp;&nbsp;&nbsp;</b>

                            <b>{{item.AccountName}}</b>
                        </div>
                        <div class="col-xs-4">
                            <img [src]="item.CurrencyFlagPath" alt="" class="flag pull-right" />
                        </div>

                        <div class="col-xs-12 m-t-10">
                            {{'Amount' | translate}} : {{fnReceivedAmountString(item)}}
                        </div>
                        <div class="col-xs-12 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Rate' | translate}} : {{item.TradingRate | numberFormat}}
                        </div>

                        <div class="col-xs-12 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Inverse Rate' | translate}} : {{item.InverseTradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-12 m-t-10" *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </div>
                        <div class="col-xs-12 m-t-10" *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </div>

                        <div class="col-xs-12 m-t-10">
                            {{fnLocalValue(item)}}
                        </div>
                        <div class="col-xs-12 m-t-10" *ngIf="item.ReferenceNo">
                            {{'Reference' | translate}} : {{item.ReferenceNo}}
                        </div>
                        <div class="col-xs-12 m-t-10" *ngIf="item.isCrypto">
                            {{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}
                        </div>
                        <div class="col-xs-12 m-t-10">
                            <div [innerHtml]="fnComment(item.PreComment)">
                            </div>
                        </div>
                        <div class="col-xs-12 m-t-10">
                            <!-- CHEQUE DETAILS -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 m-t-b-50 ">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETAILS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 m-t-b-50 ">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="column">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="column">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class=""
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0  m-t-b-50 ">
                                    <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="border-top"></div>
                        </div>
                    </div>
                    <div class="m-t-10">
                        {{DisclaimerText}}
                    </div>
                </div>
            </div>
            <!-- <footer>
                <br>
                <div class="w-100 text-secondary" class="text-center">
                    <small>{{'Powered by CXR Cloud' | translate}}</small>
                    &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
                </div>
            </footer> -->
        </div>
    </div>
</div>

<div class="row" *ngIf="printFormat == 8">
    <div class="w-400 margin-auto">
        <div class="row" id="invoice-print">
            <div class="m-b-20">
                <div class="col-xs-12 f-z-20 text-center">
                    <b>{{OwnerDetails?.OwnerCompanyName}}</b>
                </div>
                <div class="col-xs-12 branch-details">
                    <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                    <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                    <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                    <div>{{BranchDetails?.BranchPhone}}</div>
                </div>
            </div>
            <div class="main">
                <div class="border-top">
                    <h5 class="margin-0 m-t-10">{{InvoiceExID}} <small class="pull-right">{{InvoiceDate}}
                        </small></h5>
                    <h6 class="margin-0 red">
                        <small class="pull-right">{{InvoiceTime}}</small>
                    </h6>
                    <!-- <h6>
                        <span>{{ActualInvoiceDate}}</span>
                    </h6> -->
                    <h6>
                        {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                    </h6>
                    <h5 class="margin-0">{{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}</h5>
                    <table style="width: 100%;">
                        <tr>
                            <div class="" *ngIf="isEWire">
                                <h5 class="bg-ash margin-0 text-center">
                                    <b>{{'Receipient Details' | translate}}</b>
                                </h5>
                                <div *ngFor="let item of Receipient; let i = index">
                                    <div class="column">
                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>

                                    </div>
                                    <div class="column">
                                        {{'Last Name' | translate}} : {{item.LastName}}
                                        <br>
                                        {{'DOB' | translate}} : {{item.DateOfBirth}}
                                        <br>
                                        {{'Contact Number' | translate}} :
                                        {{item.ContactNumber}}
                                        <br>
                                        {{'Document ID' | translate}} :
                                        {{item.PictureIDNo}}
                                        <br>
                                        {{'Document Type' | translate}} :
                                        {{item.PictureIDType}}
                                        <br>
                                        {{'Date of Issue' | translate}} :
                                        {{item.PictureIDIssue}}
                                        <br>
                                        {{'Expiry Date' | translate}} :
                                        {{item.PictureIDExpiry}}
                                        <br>
                                        {{'Country Of Issue' | translate}} :
                                        {{item.CountryOfIssue}}

                                    </div>
                                </div>
                            </div>
                        </tr>
                    </table>
                    <h5 class="bg-ash m-t-10 text-center">
                        <b>{{'Invoice Details' | translate}}</b>
                    </h5>
                    <div class="row" *ngFor="let item of Rows; let i = index">


                        <div class="col-xs-4 m-t-10">


                            <b *ngIf="item.isReceived==true && item.Currency==item.BaseCurrency"> {{'Received' |
                                translate}}</b>
                            <b *ngIf="item.isReceived==false && item.Currency==item.BaseCurrency"> {{'Paid' |
                                translate}}</b>
                            <b *ngIf="item.isReceived==true && item.Currency!=item.BaseCurrency"> {{'Buy' |
                                translate}}</b>
                            <b *ngIf="item.isReceived==false && item.Currency!=item.BaseCurrency"> {{'Sell' |
                                translate}}</b>
                        </div>
                        <div class="col-xs-4 m-t-10">
                            {{item.Currency}}
                        </div>
                        <div class="col-xs-4 m-t-10">
                            {{item.isReceived ? item.In : item.Out}}
                        </div>
                        <div *ngIf="item.Currency!=item.BaseCurrency">
                            <div class="col-xs-4 m-t-10">
                                {{'Rate' | translate}}
                            </div>
                            <div class="col-xs-4 m-t-10">
                                {{item.BaseCurrency}}
                            </div>
                            <div class="col-xs-4 m-t-10" *ngIf="item.Currency != item.BaseCurrency">
                                {{item.TradingRate | numberFormat}}
                            </div>
                            <div class="col-xs-4 m-t-5">

                            </div>
                            <div class="col-xs-4 m-t-5">
                                {{item.Currency}}
                            </div>
                            <div class="col-xs-4 m-t-5" *ngIf="item.Currency != item.BaseCurrency">
                                {{item.InverseTradingRate | numberFormat}}
                            </div>
                            <div class="col-xs-4 m-t-10 f-z-18">
                                <b>= {{item.BaseCurrency}}</b>
                            </div>
                            <div class="col-xs-4 m-t-10">

                            </div>
                            <div class="col-xs-4 m-t-10 f-z-18">
                                <b> {{(item.isReceived ? item.DebitLC : item.CreditLC) | numberFormat}}</b>
                            </div>
                            <div class="col-xs-4 m-t-10 f-z-18" *ngIf="item.ReferenceNo">
                                {{'Reference' | translate}} : {{item.ReferenceNo}}
                            </div>
                            <div class="col-xs-4 m-t-10 f-z-18" *ngIf="item.isCrypto">
                                {{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}
                            </div>
                        </div>
                        <div class="col-xs-12 m-t-10">
                            <!-- CHEQUE DETALS -->
                            <div class="" *ngIf="item.isCheque">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h4>
                                <b>
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b>
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b>
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b>
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETALS -->
                            <div class="" *ngIf="item.isWire">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h4>
                                <div class="column">
                                    <b>
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b>
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b>
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b>
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b>
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b>
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b>
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b>
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b>
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>

                                    <b>
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b>
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>

                                    <b>
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b>
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="column">
                                    <b>
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>

                                    <b>
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>

                                    <b>
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>

                                    <b>
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>

                                    <b>
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>

                                    <b>
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b>
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b>
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b>
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <!-- <div class="" *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <h4 class="bg-ash margin-0 text-center">
                                    <b>{{'Denominations Paid' | translate}}</b>
                                </h4>
                                <div [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div> -->

                        </div>
                        <div class="col-xs-12">
                            <div class="border-top"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 m-t-10 m-b-20">
                            {{'You have been Served by' | translate}}&nbsp;{{ClerkName || UserName}}
                        </div>

                    </div>
                    <div class="m-t-10 row">
                        <div class="col-xs-4">
                            <b> {{'Note' | translate}} :</b>
                        </div>
                        <div class="col-xs-8">
                            <div>
                                <p [innerHTML]="BranchDetails?.Notes"> </p>
                            </div>
                        </div>

                        <!-- {{DisclaimerText}} -->
                    </div>
                </div>
            </div>
            <!-- <footer>
                <br>
                <div class="w-100 text-secondary text-center">
                    <small>{{'Powered by CXR Cloud' | translate}}</small>
                    &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
                </div>
            </footer> -->
        </div>
    </div>
</div>

<div class="row" *ngIf="printFormat == 9">
    <div class="margin-auto" style="width: 160px !important;">
        <div class="row" id="invoice-print">
            <div class="m-b-20">
                <div class="col-xs-12 text-center" style="font-size: 15;">
                    <b>{{OwnerDetails?.OwnerCompanyName}}</b>
                </div>
                <div class="col-xs-12 branch-details-mini">
                    <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                    <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                    <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                    <div>{{BranchDetails?.BranchPhone}}</div>
                </div>
            </div>
            <div class="main">
                <div class="border-top">
                    <h6 class="margin-0 m-t-5">{{InvoiceExID}} <small class="pull-right">
                        </small></h6>
                    <div class="col-xs-12 font-10">
                        <div class="row margin-t-b-0">
                            {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                        </div>
                        <div class="row margin-t-b-0">
                            {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                        </div>
                        <div class="row margin-t-b-0">
                            {{'Created Date' | translate}} : {{InvoiceDate}}
                            {{InvoiceTime}}
                        </div>
                    </div>

                    <h6 class="margin-0">{{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}</h6>
                    <table style="width: 100%;">
                        <tr>
                            <div class="" *ngIf="isEWire">
                                <h6 class="bg-ash margin-0 text-center">
                                    <b>{{'Receipient Details' | translate}}</b>
                                </h6>
                                <div *ngFor="let item of Receipient; let i = index">
                                    <div class="column">

                                        {{'First Name' | translate}} :
                                        {{item.FirstName}}
                                        <br>
                                        {{'ID' | translate}} :
                                        {{item.LinkExtID}}
                                        <br>
                                        {{'Email' | translate}} :
                                        {{item.Email}}
                                        <br>
                                        {{'Occupation' | translate}} :
                                        {{item.Occupation}}
                                        <br>
                                        {{'Nationality' | translate}} :
                                        {{item.Nationality}}
                                        <br>
                                        {{'Address' | translate}} : {{item.Address}}
                                        <br>
                                        {{'City' | translate}} : {{item.City}}
                                        <br>
                                        {{'State' | translate}} : {{item.State}}
                                        <br>

                                    </div>
                                    <div class="column">
                                        {{'Last Name' | translate}} : {{item.LastName}}
                                        <br>
                                        {{'DOB' | translate}} : {{item.DateOfBirth}}
                                        <br>
                                        {{'Contact Number' | translate}} :
                                        {{item.ContactNumber}}
                                        <br>
                                        {{'Document ID' | translate}} :
                                        {{item.PictureIDNo}}
                                        <br>
                                        {{'Document Type' | translate}} :
                                        {{item.PictureIDType}}
                                        <br>
                                        {{'Date of Issue' | translate}} :
                                        {{item.PictureIDIssue}}
                                        <br>
                                        {{'Expiry Date' | translate}} :
                                        {{item.PictureIDExpiry}}
                                        <br>
                                        {{'Country Of Issue' | translate}} :
                                        {{item.CountryOfIssue}}

                                    </div>
                                </div>
                            </div>
                        </tr>
                    </table>
                    <h6 class="bg-ash m-t-5 text-center">
                        <b>{{'Invoice Details' | translate}}</b>
                    </h6>
                    <div class="row" style="margin-top: -10px;" *ngFor="let item of Rows; let i = index">
                        <div class="col-xs-8 font-10 margin-t-b-0">
                            <b class="font" [innerHtml]="fnComment(item.PreComment)"></b>
                        </div>
                        <div class="col-xs-4 font-10 margin-t-b-0">
                            {{fnReceivedAmountString(item)}}
                        </div>
                        <div class="col-xs-6  font-10 margin-t-b-0" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Rate' | translate}} : {{item.TradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-6  font-10 margin-t-b-0" *ngIf="item.Currency != item.BaseCurrency">
                            {{'Inverse Rate'}}: {{item.InverseTradingRate | numberFormat}}
                        </div>
                        <div class="col-xs-6  font-10 margin-t-b-0 "
                            *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </div>
                        <div class="col-xs-6  font-10 margin-t-b-0 "
                            *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </div>
                        <div class="col-xs-6 font-10 margin-t-b-0">
                            {{fnLocalValue(item)}}
                        </div>
                        <div class="col-xs-6 font-10 margin-t-b-0" *ngIf="item.ReferenceNo">
                            {{'Reference' | translate}} : {{item.ReferenceNo}}
                        </div>
                        <div class="col-xs-6 font-10 margin-t-b-0" *ngIf="item.isCrypto">
                            {{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}
                        </div>
                        <!-- <div class="col-xs-12 font-10 margin-t-b-0">
                            <div >
                            </div>
                        </div> -->
                        <div class="col-xs-12 ">
                            <!-- CHEQUE DETALS -->
                            <div class="font-10" *ngIf="item.isCheque">
                                <h5 class="bg-ash margin-0 text-center">
                                    <b>{{'Cheque Details' | translate}}</b>
                                </h5>
                                <b class="margin-t-b-0 font-10">
                                    {{'Payable To' | translate}} :
                                </b>
                                {{item.ChequeFormData.PayableTo}}
                                <br>
                                <b class="margin-t-b-0 font-10">
                                    {{'Register Date' | translate}} :
                                </b>
                                {{item.ChequeFormDataRegisterDate}}
                                <br>
                                <b class="margin-t-b-0 font-10">
                                    {{'Deposit Date' | translate}} :
                                </b>
                                {{item.ChequeFormData.DepositDate}}
                                <br>
                                <b class="margin-t-b-0 font-10">
                                    {{'Due Date Of Cheque' | translate}} :
                                </b>
                                {{item.ChequeFormData.DueDateOfCheque}}
                                <br>
                            </div>
                            <!-- WIRE DETALS -->
                            <div class="" *ngIf="item.isWire">
                                <h6 class="bg-ash margin-0 text-center">
                                    <b>{{'Wire Details' | translate}}</b>
                                </h6>
                                <div class="col-xs-12 font-10">
                                    <b class="margin-t-b-0 font-10">
                                        {{'Bank Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankName}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Account Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.AccountNumber}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Swift BIC Transit' | translate}} :
                                    </b>
                                    {{item.WireFormData.SwiftBICTransit}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Routing Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.RoutingCode}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Institution Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.InstitutionNo}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Branch Transit Number' | translate}} :
                                    </b>
                                    {{item.WireFormData.BranchTransitNo}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Banking Address' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankingAddress}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Bank City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCity}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Bank State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankStateProvince}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Bank Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankCountry}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Bank Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BankZipCode}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.Country}}
                                    <br>
                                    <b class="margin-t-b-0 font-10">
                                        {{'Beneficiary Street' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStreet}}
                                </div>
                                <div class="col-xs-12 font">
                                    <b class="margin-t-b-0">
                                        {{'Beneficiary City' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCity}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Beneficiary State Province' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryStateProvince}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Beneficiary Country' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryCountry}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Beneficiary Zip Code' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryZipCode}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Register Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.RegisterDate}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Estimated Receive Date' | translate}} :
                                    </b>
                                    {{item.WireFormData.EstimatedReceiveDate}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Beneficiary Phone' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryPhone}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Beneficiary Name' | translate}} :
                                    </b>
                                    {{item.WireFormData.BeneficiaryName}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Further Credit To' | translate}} :
                                    </b>
                                    {{item.WireFormData.FurtherCreditTo}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Method Of Payment At Source' | translate}} :
                                    </b>
                                    {{item.WireFormData.MethodOfPaymentAtSource}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'WatchList Remarks' | translate}} :
                                    </b>
                                    {{item.WireFormData.WatchListRemarks}}
                                    <br>
                                    <b class="margin-t-b-0">
                                        {{'Relation with Customer' | translate}} :
                                    </b>
                                    {{item.WireFormData.RelationWithCustomer}}
                                    <br>
                                </div>
                            </div>
                            <!-- CASH DETAILS -->
                            <div class="font"
                                *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                                <div class="font-10" [innerHtml]="RowDetailsInDepth(item)">
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="border-top"></div>
                        </div>
                    </div>
                    <div class="margin-t-b-0 font-10">
                        {{DisclaimerText}}
                    </div>
                </div>
            </div>
            <!-- <footer>
                <div class="w-100 text-secondary text-center">
                    <small>{{'Powered by CXR Cloud' | translate}}</small>&nbsp;
                    <img class="cxr-logo margin-auto" [src]=" cxrLogoPath">
                </div>
            </footer> -->
        </div>
    </div>
</div>


<div class="row" *ngIf="printFormat == 10">
    <div id="invoice-print">
        <div class="header">
            <div class="">
                <img [src]="OwnerDetails.LogoPath" (error)="error()" (load)="loadedImage()" alt="" class="owner-logo" />
            </div>
            <div class="summary"><b>{{OwnerDetails?.OwnerCompanyName}}</b></div>
            <div class="standard-branch-details">
                <div>{{BranchDetails?.BranchName}} {{BranchCodeDisplay(BranchDetails?.BranchCode)}}</div>
                <div>{{BranchDetails?.Address}} {{BranchDetails?.City}}</div>
                <div> {{BranchDetails?.StateProvince}} {{BranchDetails?.PostalCode}}</div>
                <div>{{BranchDetails?.BranchPhone}}</div>
            </div>
        </div>
        <div class="main">
            <div class="invoice-box">
                <h3 class="margin-0">{{InvoiceExID}}</h3>
                <!-- <h4 class="margin-0 red">{{'is Transfer' | translate}}: {{isTransfer ? 'Yes' : 'No'}}</h4> -->
                <h4>
                    {{'Invoice Date' | translate}} : <span>{{ActualInvoiceDate}}</span>
                </h4>
                <h4>
                    {{'Invoice Sequence' | translate}} : {{SequenceNumber}}
                </h4>
                <h4>
                    {{'Created Date' | translate}} : {{InvoiceDate}}
                    {{InvoiceTime}}
                </h4>
                <h4 class="margin-0">
                    {{'Customer' | translate}} : {{CustomerDetails?.CustomerName}}
                </h4>
                <h3 class="bg-ash margin-0 m-t-10 m-b-10 text-center">
                    <b>{{'Invoice Details' | translate}}</b>
                </h3>
                <table *ngFor="let item of Rows; let i = index" [ngClass]="{'border-top' : i != 0}">
                    <tr>
                        <td class="col-sm-3">{{item.AccountName}}</td>
                        <td class="col-sm-6" colspan="2"><b>{{item.PreComment}}</b></td>
                        <td class="col-sm-3" rowspan="2">
                            <img [src]="item.CurrencyFlagPath" alt="" class="flag pull-right" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Amount' | translate}} : {{fnReceivedAmountString(item)}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Rate' | translate}} : {{item.TradingRate |
                            numberFormat}}</td>
                        <td *ngIf="item.Currency != item.BaseCurrency">{{'Inverse Rate' | translate}} :
                            {{item.InverseTradingRate | numberFormat}}</td>
                    </tr>
                    <tr>
                        <td *ngIf="item.FeeLocal != null && item.FeeLocal != 0">
                            {{'Fee' | translate}} : {{item.FeeLocal | numberFormat : 2}}
                        </td>
                        <td *ngIf="item.PercentFee != null && item.PercentFee != 0">
                            {{'Fee in %' | translate}} : {{item.PercentFee | numberFormat : 2}}
                        </td>
                        <td>
                            {{fnLocalValue(item)}}
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="item.ReferenceNo">{{'Reference' | translate}} : {{item.ReferenceNo}}</td>
                        <td *ngIf="item.isCrypto">{{'Wallet ID'}} : {{item.CryptoTransaction?.WalletID}}</td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <!-- CHEQUE DETAILS -->
                            <div class="" *ngIf="item.isCheque">

                                <span>
                                    {{'Cheque No' | translate}} :
                                </span>
                                {{item.ChequeFormData.ChequeNumber}}
                                <br>
                                <span>
                                    {{'Payable To' | translate}} :
                                </span>
                                {{item.ChequeFormData.PayableTo}}
                                <br>

                                <span>
                                    {{'Deposit Date' | translate}} :
                                </span>
                                {{item.ChequeFormData.DepositDate | date}}
                                <br>
                                <span>
                                    {{'Due Date Of Cheque' | translate}} :
                                </span>
                                {{item.ChequeFormData.DueDateOfCheque | date}}
                                <br>
                            </div>
                            <!-- eWIRE DETAILS -->
                            <div class="" *ngIf="item.isEWire">
                                <span *ngIf="item.isReceived">
                                    <span>
                                        {{'Received eWire' | translate}} :
                                        {{item.ReferenceNo}}&nbsp; {{'for' | translate}} :
                                        {{CustomerDetails?.CustomerExtID}}
                                    </span>

                                    <br>
                                    <span>
                                        {{'Sender' | translate}}:
                                        {{item.SendEWireFormData.BeneficiaryDetails.FirstName}} -
                                        {{item.SendEWireFormData.BeneficiaryDetails.LinkExtID}}
                                        {{'Paid' | translate}}&nbsp;{{item.In}}&nbsp;{{item.Currency}}
                                        {{'From' | translate}}&nbsp; {{item.Country}}
                                    </span>
                                    <br>
                                    <span>
                                        {{'Sender Address' | translate}}:
                                        {{item.SendEWireFormData.BeneficiaryDetails.Address}}
                                        {{item.SendEWireFormData.BeneficiaryDetails.AddressCountry}}
                                    </span>
                                </span>
                                <span *ngIf="!item.isReceived">
                                    <span>
                                        {{'Sold eWire' | translate}}:
                                        {{item.ReferenceNo}}&nbsp;{{'by' | translate}}:
                                        {{CustomerDetails?.CustomerExtID}}
                                    </span>

                                    <br>
                                    <span>
                                        {{'Beneficiary' | translate}}:
                                        {{item.SendEWireFormData.BeneficiaryDetails.FirstName}} -
                                        {{item.SendEWireFormData.BeneficiaryDetails.LinkExtID}}&nbsp;
                                        {{'Paid' | translate}}&nbsp;{{item.Out}} &nbsp;{{item.Currency}}
                                        {{'From' | translate}}&nbsp; {{item.Country}}
                                    </span>
                                    <br>
                                    <span>
                                        {{'Beneficiary Address' | translate}}:&nbsp;
                                        {{item.SendEWireFormData.BeneficiaryDetails.Address}}
                                        {{item.SendEWireFormData.BeneficiaryDetails.AddressCountry}}
                                    </span>
                                </span>

                            </div>

                        </td>
                    </tr>
                    <div class="" *ngIf="item.isCash && item.DenominationsInOutList?.length > 0 && showDenominations">
                        <h4 class="bg-ash margin-0 text-center">
                            <!-- <b>{{'Denominations Paid' | translate}}</b> -->
                        </h4>
                        <div [innerHtml]="RowDetailsInDepth(item)">
                        </div>
                    </div>
                </table>

                <div class="border-top"></div>
                <h4 *ngIf="Remarks">
                    {{'Remarks' | translate}} : <span [innerHTML]="Remarks"></span>
                </h4>
                <h4 *ngIf="PurposeOfTransaction">
                    {{'Purpose of Transaction' | translate}} : {{PurposeOfTransaction}}
                </h4>
                <h4 *ngIf="SourceOfFunds">
                    {{'Source Of Funds' | translate}} : {{SourceOfFunds}}
                </h4>
                <div class="border-top" *ngIf="SourceOfFunds"></div>
                <div class="m-t-10">
                    {{DisclaimerText}}
                </div>

            </div>
        </div>
        <!-- <footer>
            <br>
            <div class="w-100 text-secondary text-center">
                <small>{{'Powered by CXR Cloud' | translate}}</small>
                &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
            </div>
        </footer> -->
    </div>
</div>
<cxr-stamps *ngIf="stamp.show && !isThermal" [message]="stamp.message" [color]="stamp.color"
    transform="translate(-10%, -50%) rotate(-40deg)">
</cxr-stamps>


<span *ngIf="stamp.show && isThermal">
    <h3 class="water-mark " style="color: #ccc;">{{stamp.message | translate}}
    </h3>
</span>
