<div [fxLayout]="(menuParentId == 0) ? 'row' : 'column'" fxLayoutAlign="center center">
    <div *ngFor="let menu of menuItems;" class="horizontal-menu-item w-100">
        <a *ngIf="menu.routerLink && !menu.hasSubMenu " mat-button fxLayout="row"
            [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [routerLink]="[menu.routerLink || '']" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact:true}" [matTooltip]="menu.title" matTooltipPosition="above"
            [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <!-- <span class="m-r-5" *ngIf="menu.icon"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
            <span class="horizontal-menu-title" [ngClass]="{'emoji-icon' :menu.isEmoji }">{{menu.title |
                translate}}</span>
        </a>
        <a *ngIf="menu.href && !menu.subMenu" mat-button fxLayout="row" [routerLink]="[menu.routerLink || '']"
            routerLinkActive="active-link"
            [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [attr.href]="menu.href || ''" [attr.target]="menu.target || ''" [matTooltip]="menu.title"
            matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id"
            [ngClass]="{'disable-menu' : hideReports && menu.routerLink.match('crm/reports')}">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <!-- <span class="m-r-5"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
            <span class="horizontal-menu-title">{{menu.title | translate}}</span>
        </a>
        <a *ngIf="menu.hasSubMenu && menu.routerLink" mat-button fxLayout="row" [routerLink]="[menu.routerLink || '']"
            routerLinkActive="active-link"
            [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [matTooltip]="menu.title" matTooltipPosition="above"
            [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'" [id]="'horizontal-menu-item-'+menu.id"
            [ngClass]="{'disable-menu' :(hideReports && menu.routerLink.match('crm/reports')) || 
            (hidePLStatement && menu.routerLink.match('reports/financial/pl-statement'))}" class="">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <!-- <span class="m-r-5"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
            <span class="horizontal-menu-title">{{menu.title | translate}}</span>
            <!-- <span *ngIf="menu.routerLink == 'till-report'">TillReport</span> -->
        </a>

        <a *ngIf="!menu.routerLink && (menu.title != 'Delete All Transactions' || showDeleteAllTransactions)" mat-button
            fxLayout="row" (click)="openDialog(menu.title)"
            [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [matTooltip]="menu.title" matTooltipPosition="above"
            [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'" [id]="'horizontal-menu-item-'+menu.id"
            [ngClass]="{'disable-menu' :hideReports && menu.title == 'Till Report'}">
            <!-- <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon> -->
            <!-- <span class="m-r-5"><i [class]="menu.icon" aria-hidden="true"></i></span> -->
            <span class="horizontal-menu-title">{{menu.title | translate}}</span>
            <!-- <span *ngIf="menu.routerLink == 'till-report'">TillReport</span> -->
        </a>

        <div *ngIf="menu.hasSubMenu" class="horizontal-sub-menu" [id]="'horizontal-sub-menu-'+menu.id">
            <app-horizontal-menu [menuParentId]="menu.id"></app-horizontal-menu>
        </div>
    </div>
</div>


<script src="https://cdn.jsdelivr.net/npm/lodash@4.17.10/lodash.min.js"></script>



<!-- <div [fxLayout]="(menuParentId == 0) ? 'row' : 'column'" fxLayoutAlign="start center">
    <div *ngFor="let menu of menuItems" class="horizontal-menu-item w-100">
        <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id">
            <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon>
            <span class="horizontal-menu-title">{{menu.title}}</span>
        </a>
        <a *ngIf="menu.href && !menu.subMenu" mat-button
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id">
            <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon>
            <span class="horizontal-menu-title">{{menu.title}}</span>
        </a>
        <a *ngIf="menu.hasSubMenu" mat-button
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            [id]="'horizontal-menu-item-'+menu.id">
            <mat-icon class="horizontal-menu-icon">{{menu.icon}}</mat-icon>
            <span class="horizontal-menu-title">{{menu.title}}</span>
        </a>

        <div *ngIf="menu.hasSubMenu" class="horizontal-sub-menu" [id]="'horizontal-sub-menu-'+menu.id">
            <app-horizontal-menu [menuParentId]="menu.id"></app-horizontal-menu>
        </div>
    </div>
</div> -->
