import { Menu } from './menu.model';




export const superAdminVerticalMenuItems = [
    new Menu(1, 'Manage Owner', '/super-admin/administrator/manage-owners', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Lookup Options', '/super-admin/administrator/manage-lookups', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Flags', '/super-admin/administrator/flags', null, 'fa fa-tasks', null, true, 0),
    new Menu(4, 'Denominations', '/super-admin/administrator/denominations', null, 'fa fa-tasks', null, true, 0),
    new Menu(5, 'Settings', '/super-admin/administrator/settings', null, 'fa fa-tasks', null, true, 0),
    new Menu(6, 'Supporting Staff', '/super-admin/administrator/supprting-staff', null, 'fa fa-users', null, true, 0),
    new Menu(7, 'Importer', '/super-admin/administrator/importer', null, 'fa fa-users', null, true, 0),

    // new Menu(6, 'subscriptions', '/super-admin/administrator/subscriptions', null, 'fa fa-tasks', null, true, 0),

    new Menu(10, 'SendGrid', '/super-admin/administrator/settings/sendgrid-settings', null, 'fa fa-tasks', null, true, 5),
    new Menu(11, 'Twilio', '/super-admin/administrator/settings/twilio-settings', null, 'fa fa-tasks', null, true, 5),
    new Menu(12, 'eWire Mapping', '/super-admin/administrator/settings/e-wire-mapping', null, 'fa fa-tasks', null, true, 5),
    new Menu(13, 'Paypal', '/super-admin/administrator/settings/paypal-settings', null, 'fa fa-tasks', null, true, 5),
    new Menu(14, 'Non Capthcha Domains', '/super-admin/administrator/settings/domain', null, 'fa fa-tasks', null, true, 5),

    new Menu(8, 'Dashboard', '/super-admin/administrator/power-bi-dashboard', null, 'fa fa-tasks', null, true, 0),
    // new Menu(2, 'Admin', '/super-admin/administrator/admin', null, 'fa fa-user', null, true, 0),

    // new Menu(3, 'EULA', '/super-admin/administrator/admin/eula', null, 'fa fa-tasks', null, false, 2),
    // new Menu(4, 'Subscription', '/super-admin/administrator/admin/subscription', null, 'fa fa-cogs', null, false, 2),
    // new Menu(5, 'Settings', '/super-admin/administrator/admin/settings', null, 'fa fa-cogs', null, false, 2)
];

export const superAdminHorizontalMenuItems = [
    new Menu(1, 'Manage Owner', '/super-admin/administrator/manage-owners', null, 'fa fa-tasks', null, true, 0),
    new Menu(2, 'Lookup Options', '/super-admin/administrator/manage-lookups', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Flags', '/super-admin/administrator/flags', null, 'fa fa-tasks', null, true, 0),
    new Menu(4, 'Denominations', '/super-admin/administrator/denominations', null, 'fa fa-tasks', null, true, 0),
    new Menu(5, 'Settings', '/super-admin/administrator/settings', null, 'fa fa-tasks', null, true, 0),
    new Menu(6, 'Supporting Staff', '/super-admin/administrator/supprting-staff', null, 'fa fa-users', null, true, 0),
    new Menu(7, 'Importer', '/super-admin/administrator/importer', null, 'fa fa-users', null, true, 0),
    //  new Menu(6, 'Subscriptions', '/super-admin/administrator/subscriptions', null, 'fa fa-tasks', null, true, 0),

    new Menu(10, 'SendGrid', '/super-admin/administrator/settings/sendgrid-settings', null, 'fa fa-tasks', null, true, 5),
    new Menu(11, 'Twilio', '/super-admin/administrator/settings/twilio-settings', null, 'fa fa-tasks', null, true, 5),
    new Menu(12, 'eWire Mapping', '/super-admin/administrator/settings/e-wire-mapping', null, 'fa fa-tasks', null, true, 5),
    new Menu(13, 'Paypal', '/super-admin/administrator/settings/paypal-settings', null, 'fa fa-tasks', null, true, 5),

    new Menu(14, 'Non Capthcha Domains', '/super-admin/administrator/settings/domain', null, 'fa fa-tasks', null, true, 5),

    // new Menu(2, 'Admin', '/super-admin/administrator/admin', null, 'fa fa-user', null, true, 0),

    // new Menu(3, 'EULA', '/super-admin/administrator/admin/eula', null, 'fa fa-tasks', null, false, 2),
    // new Menu(4, 'Subscription', '/super-admin/administrator/admin/subscription', null, 'fa fa-cogs', null, false, 2),
    // new Menu(5, 'Settings', '/super-admin/administrator/admin/settings', null, 'fa fa-cogs', null, false, 2)

    new Menu(8, 'Dashboard', '/super-admin/administrator/power-bi-dashboard', null, 'fa fa-tasks', null, true, 0),
];
