import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GenericHttpService {

    constructor (private _http: HttpClient) { }
    fnGetFundTransferDetails(verificationKey: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('verificationKey', verificationKey);
        return this._http.get<responseModel>(`${ environment.apiUrl }get-fund-transfer-details`, { params: params });
    }

    fnApproveFundTransfer(val): Observable<any> {

        return this._http.post<responseModel>(`${ environment.apiUrl }accept-funds`, val);
    }
    fnGetBankNotes(iso: string, value: number, showExpired: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.set('iso', iso);
        params = params.set('showExpired', showExpired);
        params = params.set('value', value);


        return this._http.get<responseModel>(`${ environment.apiUrl }get-bankNotes`, { params: params });
    }
}
