import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiResponse, responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor (private _http: HttpClient) { }
  // login 
  fnLoginUser(val, overrideCurrentSession: boolean = false) {
    let params = new HttpParams();
    params = params.set('overrideCurrentSession', overrideCurrentSession);
    return this._http.post(environment.apiUrl + 'login-user', val, { params: params });
  }

  // login 
  fnLoginRobot(val) {
    return this._http.post(environment.apiUrl + 'login-robot', val);
  }

  // logout
  fnLogOutUser(val) {
    return this._http.post(environment.apiUrl + 'logout-user', val);
  }

  fnSendForgotPassword(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'forgot-password', val);
  }

  fnCheckVerificationCode(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'check-verification-code', val);
  }

  fnSetPassword(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'set-password', val);
  }

  fnSSOLogin(app_id: string, authentication_token: string, overrideCurrentSession?: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.set('app_id', app_id);
    params = params.set('authentication_token', authentication_token);
    params = params.set('overrideCurrentSession', overrideCurrentSession);
    return this._http.get(`${ environment.apiUrl }ssologin/authenticate`, { params: params }).pipe(
      map((res: apiResponse<any>) => ({ ...res })));
  }

  fnValidateTwoFA(email: string, otp: string) {
    let params = new HttpParams();
    params = params.set('email', email);
    params = params.set('otp', otp);
    return this._http.get(`${ environment.apiUrl }validate-2fa-code`, { params: params }).pipe(
      map((res: apiResponse<any>) => ({ ...res })));
  }

  fnResendTwoFactorOTP(email: string) {
    let params = new HttpParams();
    params = params.set('email', email);
    return this._http.get(`${ environment.apiUrl }resend-2fa-code`, { params: params }).pipe(
      map((res: apiResponse<any>) => ({ ...res })));
  }

  fnCheckCaptchaForDomain(domain: string) {
    let params = new HttpParams();
    params = params.set('domain', domain);
    return this._http.get(`${ environment.apiUrl }checkCaptchaForDomain`, { params: params }).pipe(
      map((res: apiResponse<any>) => ({ ...res })));
  }
}
