import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuillModule } from 'ngx-quill';
import { CalendarModule } from 'primeng/calendar';
import { translationUrl } from 'projects/cxr-crm/src/app/translationurl';
import { NumberFormatPipe } from '../../_pipes/number-format.pipe';
import { MaterialModule } from '../../material.module';
import { GenericAddressDialogComponent } from './_components/address/generic-address-dialog/generic-address-dialog.component';
import { AddressAutoCompleteComponent } from './_components/address/generic-address/address-auto-complete.component';
import { GenericAddressComponent } from './_components/address/generic-address/generic-address.component';
import { PostGridAddressAutoCompleteComponent } from './_components/address/generic-address/post-grid-address-auto-complete/post-grid-address-auto-complete.component';
import { AMLReviewLogComponent } from './_components/aml-review-log/aml-review-log.component';
import { GenericBankInfoDialogComponent } from './_components/bank-info/generic-bank-info-dialog/generic-bank-info-dialog.component';
import { GenericBankInfoComponent } from './_components/bank-info/generic-bank-info/generic-bank-info.component';
import { EmailValidationComponent } from './_components/email/email-validation/email-validation.component';
import { GenericEmailDialogComponent } from './_components/email/generic-email-dialog/generic-email-dialog.component';
import { GenericEmailComponent } from './_components/email/generic-email/generic-email.component';
import { KYCReviewLogComponent } from './_components/kyc-review-log/kyc-review-log.component';
import { NotesComponent } from './_components/notes/notes.component';
import { GenericPhoneNumbersDialogComponent } from './_components/phone-numbers/generic-phone-numbers-dialog/generic-phone-numbers-dialog.component';
import { GenericPhoneNumbersComponent } from './_components/phone-numbers/generic-phone-numbers/generic-phone-numbers.component';
import { GenericPictureIdDialogComponent } from './_components/picture-ids/generic-picture-id-dialog/generic-picture-id-dialog.component';
import { GenericPictureIdComponent } from './_components/picture-ids/generic-picture-id/generic-picture-id.component';
import { RequestAddressComponent } from './_customer-requests/request-address/request-address.component';
import { RequestEmailComponent } from './_customer-requests/request-email/request-email.component';
import { RequestPhoneNumbersComponent } from './_customer-requests/request-phone-numbers/request-phone-numbers.component';
import { RequestPictureIdComponent } from './_customer-requests/request-picture-id/request-picture-id.component';
import { AccountTransactionsComponent } from './account-transactions/account-transactions.component';
import { AddEditOwnerComponent } from './add-edit-owner/add-edit-owner.component';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';
import { CurrencyDropdownComponent } from './currency-dropdown/currency-dropdown.component';
import { CurrencyNotesComponent } from './currency-notes/currency-notes.component';
import { DateRangePickButtonComponent } from './date-range-pick-button/date-range-pick-button.component';
import { GenericCryptoDialogComponent } from './generic-crypto-wallet/generic-crypto-dialog/generic-crypto-dialog.component';
import { GenericCryptoWalletComponent } from './generic-crypto-wallet/generic-crypto-wallet.component';
import { GenericDenominationsComponent } from './generic-denominations/generic-denominations.component';
import { GenericFlagsComponent } from './generic-flags/generic-flags.component';
import { GenericLookupsComponent } from './generic-lookups/generic-lookups.component';
import { GenericNumberInputComponent } from './generic-number-input/generic-number-input.component';
import { NotePickButtonComponent } from './note-pick-button/note-pick-button.component';
import { PrimeCalendarComponent } from './prime-calendar/prime-calendar.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { StampsComponent } from './stamps/stamps.component';
import { TypeAheadDropDownComponent } from './type-ahead-drop-down/type-ahead-drop-down.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, translationUrl, '');
}

@NgModule({
  declarations: [
    GenericAddressComponent,
    GenericAddressDialogComponent,
    GenericEmailComponent,
    GenericEmailDialogComponent,
    GenericPhoneNumbersComponent,
    GenericPhoneNumbersDialogComponent,
    GenericPictureIdComponent,
    GenericPictureIdDialogComponent,
    GenericBankInfoComponent,
    GenericBankInfoDialogComponent,
    KYCReviewLogComponent,
    NotesComponent,
    AMLReviewLogComponent,
    RequestAddressComponent,
    RequestEmailComponent,
    RequestPhoneNumbersComponent,
    RequestPictureIdComponent,
    AddressAutoCompleteComponent,
    EmailValidationComponent,
    PostGridAddressAutoCompleteComponent,
    DateRangePickButtonComponent,
    TypeAheadDropDownComponent,
    NotePickButtonComponent,
    RichTextEditorComponent,
    StampsComponent,
    AccountTransactionsComponent,
    CountryDropdownComponent,
    CurrencyDropdownComponent,
    AddEditOwnerComponent,
    PrimeCalendarComponent,
    CurrencyNotesComponent,
    GenericDenominationsComponent,
    GenericFlagsComponent,
    GenericLookupsComponent, GenericNumberInputComponent, NumberFormatPipe, GenericCryptoWalletComponent, GenericCryptoDialogComponent,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MaterialModule,
    CalendarModule,
    QuillModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
    }),
  ],
  providers: [],
  exports: [
    DateRangePickButtonComponent,
    GenericAddressComponent,
    GenericAddressDialogComponent,
    GenericEmailComponent,
    GenericEmailDialogComponent,
    GenericPhoneNumbersComponent,
    GenericPhoneNumbersDialogComponent,
    GenericPictureIdComponent,
    GenericPictureIdDialogComponent,
    GenericBankInfoComponent,
    GenericBankInfoDialogComponent,
    KYCReviewLogComponent,
    NotesComponent,
    AMLReviewLogComponent,
    RequestAddressComponent,
    RequestEmailComponent,
    RequestPhoneNumbersComponent,
    RequestPictureIdComponent,
    EmailValidationComponent,
    TypeAheadDropDownComponent,
    DateRangePickButtonComponent,
    NotePickButtonComponent,
    RichTextEditorComponent,
    StampsComponent,
    AccountTransactionsComponent,
    CountryDropdownComponent,
    CurrencyDropdownComponent, GenericNumberInputComponent, NumberFormatPipe,
    AddEditOwnerComponent, PrimeCalendarComponent, CurrencyNotesComponent, GenericDenominationsComponent, GenericFlagsComponent, GenericLookupsComponent, GenericCryptoWalletComponent
  ]
})
export class GenericComponentsModule { }
