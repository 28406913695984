import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { apiResponse, responseModel } from 'projects/cxr-crm/src/app/pages/_models/response.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaLookupOptionsService {

  constructor (private _http: HttpClient) { }
  fnGetCountries() {
    // return this._http.get<any[]>('https://restcountries.eu/rest/v2/all');
    return this._http.get<any[]>(environment.apiUrl + 'get-countries-list-dd');
  }
  getAddressAutoCompleteTypes(): Promise<Map<number | string, string>> {
    return this._http.get<apiResponse<Map<number | string, string>>>(environment.apiUrl + 'addressautocompletetypes')
      .pipe(map(x => { return x.Data; })).toPromise();

  }
  getExchnageRateServers(): Promise<Map<number | string, string>> {
    return this._http.get<apiResponse<Map<number | string, string>>>(environment.apiUrl + 'exchangeservertypes')
      .pipe(map(x => { return x.Data; })).toPromise();

  }
  // Add and edit owner details
  fnUpsertOwner(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-owner', val);
  }
  //
  fnPopulateDenominationsToAllOwners() {
    return this._http.post(environment.apiUrl + 'populate-denominations-to-all-owner', null);
  }

  fnPopulateLookupsToAllOwners() {
    return this._http.post(environment.apiUrl + 'populate-lookups-to-all-owner', null);
  }
  fnPopulateDenominationsToOwner(val) {
    return this._http.post(environment.apiUrl + 'populate-denominations-to-owner', val);
  }
  fnPopulateLookupsToOwner() {
    return this._http.post(environment.apiUrl + 'populate-lookups-to-owner', GetUserObject.GetOwnerIDObject());
  }
  //
  fnUpsertSADenomination(val) {
    return this._http.post(environment.apiUrl + 'upsert-sa-denomination', val);
  }

  //fetch owner info
  fnFetchOwnerInfo(val) {
    return this._http.post(environment.apiUrl + 'fetch-owner-info', val);
  }
  // Delete owner.
  fnOwnerDelete(val) {
    return this._http.post(environment.apiUrl + 'delete-owner', val);
  }
  fnGetLookupsList(values?) {
    return this._http.get(environment.apiUrl + 'get-LookupsList?OwnerID=' + null);
  }
  fnLookupDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-Lookup', values);
  }
  fnLookupUpsert(values) {
    return this._http.post(environment.apiUrl + 'upsert-Lookups', values);
  }
  fnGetCodeNames() {
    return this._http.post(environment.apiUrl + 'get-CodeMasterNames', null);
  }

  // flags
  fnGetFlags() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-flags');
  }
  fnUpsertFlag(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-flag', val);
  }
  fnDeleteFlag(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-Flag', val);
  }

  // send grid screen details
  fnFetchSendGridDetails() {
    return this._http.post<responseModel>(environment.apiUrl + 'fetch-sendgrid-details', null);
  }
  fnUpsertSendGridDetails(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-sendgrid-details', val);
  }

  // twilio screen details
  fnFetchTwilioSMSDetails() {
    return this._http.post<responseModel>(environment.apiUrl + 'fetch-twilio-details', null);
  }
  fnUpsertTwilioSMSDetails(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-twilio-details', val);
  }

  // paypal screen details
  fnFetchPaypalDetials() {
    return this._http.post<responseModel>(environment.apiUrl + 'fetch-paypal-details', null);
  }
  fnUpsertPaypalDetials(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-paypal-details', val);
  }

  // ewire owners mapping
  fnDeleteeWireOwnerMapping(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }sa/delete-ewire-mapping`, val);
  }
  fnUpserteWireOwnerMapping(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }sa/upsert-ewire-mapping`, val);
  }
  fnFetcheWireOwnerMappingDD() {
    return this._http.post<responseModel>(`${ environment.apiUrl }sa/get-ewires-mapping-DD`, null);
  }
  fnFetcheWireOwnerMappingDetails(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }sa/get-ewire-mapping-details`, val);
  }

  fnFetchTimeZones() {
    return this._http.get<responseModel>(`${ environment.apiUrl }generic/get-time-zones`);
  }

  fnLoginAsOwner(val) {
    let params = new HttpParams();
    params = params.set('userId', val);
    return this._http.get<responseModel>(environment.apiUrl + 'login-as-owner', { params: params });
  }

  fnResendVerificationLink(val) {
    return this._http.post(environment.apiUrl + 'resend-invitation-email', val);
  }
  fnDeleteAllTransactionsOfOwner(val) {
    let params = new HttpParams();
    params = params.set('ownerId', val);
    return this._http.get<responseModel>(environment.apiUrl + 'delete-all-transactions', { params: params });
  }
  //
  fnUpsertSuppotingStaff(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-supporting-staff', val);
  }

  //fetch owner info
  fnDeleteSuppotingStaff(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-supporting-staff', val);
  }
  // Delete owner.
  fnGetSupportingStaffList() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-supporting-staff-list');
  }

  // Owner Status Drop Down
  fnGetOwnerStatusDD() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-owner-status-DD');
  }

  // set database details info details
  fnUpsertDatabseInfo(val) {
    return this._http.post(environment.apiUrl + 'upsert-database-details', val);
  }
  // set backup databse
  fnBackupdatabse() {
    return this._http.get(environment.apiUrl + 'backup-database');
  }

  //  Restore databse
  fnRestoreDatabase() {
    return this._http.get(environment.apiUrl + 'restore-database');
  }

  //  Test send grid
  fnTestSendGridCredentials(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'test-sendgrid-credentials', val);
  }

  //  Get Domain
  fnGetDomains() {
    return this._http.get<responseModel>(environment.apiUrl + 'get-domains');
  }

  //  Add Domain
  fnAddDomain(val: string) {
    let param = new HttpParams();
    param = param.append('domain', val);
    return this._http.get<responseModel>(environment.apiUrl + 'add-domain', { params: param });
  }

  //  Delete Domain
  fnDeleteDomain(val: string) {
    let param = new HttpParams();
    param = param.append('domain', val);
    return this._http.get(environment.apiUrl + 'delete-domain', { params: param });
  }

}
