import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseModel } from '../_models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ManageContactService {
  headers: { headers: any; };
  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  //#region Individual Api's
  // Function to upsert Individual KYC Review
  fnIndividualKYCReview(value) {
    return this._http.post<responseModel>(`${ environment.apiUrl }upsert-individual-kyc-review`, value);
  }
  //#endregion

  // Function to upsert company KYC Review
  fnCompanyKYCReview(value) {
    return this._http.post<responseModel>(`${ environment.apiUrl }upsert-company-kyc-review`, value);
  }

  // function to upsert company into the database
  fnCompanyUpsert(values, duplicateConfirmation?: boolean) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-company?acceptDuplicate=' + duplicateConfirmation, values);
  }
  fnFetchCompanyDetails(values) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-company-details', values);
  }
  //function to delete tthe company
  fnCompanyDelete(values) {
    return this._http.post(environment.apiUrl + 'delete-company', values);
  }
  //function to Fetch the individual data
  fnFetchIndividualData(val) {
    return this._http.get<responseModel>(environment.apiUrl + 'get-individual-data?IndividualID=' + val);
  }
  fnFetchIndividualDataForm(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'fetch-individual-data', val);
  }
  fnIndividualUpsert(val, duplicateConfirmation?: boolean) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-individual?acceptDuplicate=' + duplicateConfirmation, val);
  }
  fnIndividualDelete(val) {
    return this._http.post(environment.apiUrl + 'delete-individual', val);
  }


  fnIndividualPhoneUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-individual-phone', val);
  }
  fnIndividualEmailUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-individual-email', val);
  }
  fnIndividualSocialHandleUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-individual-social-handle', val);
  }
  fnIndividualAddressUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-individual-address', val);
  }
  fnIndividualNoteUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-individual-note', val);
  }


  fnIndividualDataDelete(val, data) {
    return this._http.post(environment.apiUrl + 'delete-individual-' + data, val);
  }

  fnIndividualPictureIDUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-pictureID', val);
  }
  fnIndividualPictureIDDelete(val) {
    return this._http.post(environment.apiUrl + 'delete-individual-picture-id', val);
  }
  fnIndividualPictureIDGet(val) {

    return this._http.get(environment.apiUrl + 'get-individual-picture-ID?IndividualID=' + val);
  }



  fnIndividualBankInfoUpsert(val) {
    return this._http.post(environment.apiUrl + 'upsert-individual-bank-info', val);
  }
  fnIndividualBankInfoDelete(val) {
    return this._http.post(environment.apiUrl + 'delete-individual-bank-info', val);
  }
  fnIndividualBankInfoGet(val) {
    return this._http.get(environment.apiUrl + 'get-individual-bank-info?IndividualID=' + val);
  }


  // Link 
  fnUpsertLinkWithBankInfo(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-link', val);
  }
  fnUpsertLinkEmail(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-link-email', val);
  }
  fnUpsertLinkPhone(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-link-phone', val);
  }
  fnUpsertLinkAddress(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-link-address', val);
  }
  fnLinkDataDelete(val, data) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-link-' + data, val);
  }
  fnDeleteLink(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-link', val);
  }
  fnGetLinkDetials(val, UniqueID, CustomerExtID) {
    return this._http.get<responseModel>(environment.apiUrl + 'get-link-data?LinkID=' + val + '&UniqueID=' + UniqueID + '&CustomerExtID=' + CustomerExtID);
  }
  fnUpsertLinkPictureID(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-link-pictureID', val);
  }
  fnCheckAgainstSanctionList(val) {
    let headers = new HttpHeaders();
    headers = headers.set('isCheckAgainstSanctionList', 'true');
    return this._http.post<responseModel>(environment.apiUrl + 'check-Link-against-sanctionlist', val, { headers: headers });
  }
  fnFetchLinkByID(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'fetch-link-by-id', val);
  }
  fnFetchCompanyNameDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-company-name-dd', val);
  }
  fnFetchOccupationDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'get-occupation-dd', val);
  }
  fnUpsertThirdParty(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-third-party', val);
  }
  fnDeleteThirdParty(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'delete-third-party', val);
  }

  fnAddStakeholder(val) {
    return this._http.post<responseModel>(environment.apiUrl + 'link-stakeholder', val);
  }

  fnRemoveStakeholder(val) {
    let params = new HttpParams();
    params = params.set('stakeholderId', val);
    return this._http.get<responseModel>(environment.apiUrl + 'remove-stakeholder', { params: params });
  }

  fnGetStakeholderDetails(val) {
    let params = new HttpParams();
    params = params.set('stakeholderId', val);
    return this._http.get<responseModel>(environment.apiUrl + 'get-stakeholder-details', { params: params });
  }

  fnFetchIndividualDataBeta(val) {
    return this._http.get<responseModel>(environment.apiUrl + 'get-individual-data-beta?IndividualID=' + val);
  }

  fnIndividualUpsertBeta(val, duplicateConfirmation?: boolean) {
    return this._http.post<responseModel>(environment.apiUrl + 'upsert-individual-beta?acceptDuplicate=' + duplicateConfirmation, val);
  }


  //#endregion
}
