import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, '🏠', '/crm/dashboard', null, '', null, false, 0, true),
    new Menu(2, 'Contacts', '/crm/manage', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Currencies', '/crm/currency', null, 'fa fa-money', null, true, 0),
    new Menu(12, 'Products', '/crm/products', null, 'fa fa-user', null, true, 0),
    new Menu(11, 'Bookings', '/crm/bookings', null, 'fa fa-user', null, false, 0),
    new Menu(4, 'Modules', '/crm/transactions', null, 'fa fa-files-o', null, true, 0),
    new Menu(9, 'Orders', '/crm/orders', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(8, 'eWire', '/crm/ewires', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(5, 'Teller', '/crm/teller', null, 'fa fa-money', null, true, 0),
    new Menu(6, 'Reports', '/crm/reports', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(7, 'AML', '/crm/compliance', null, 'fa fa-tasks', null, true, 0),
    new Menu(10, 'Admin', '/crm/admin', null, 'fa fa-user', null, true, 0),


    new Menu(300, 'Currency Grid', '/crm/currency/currency-grid', null, 'fa fa-tasks', null, false, 3),
    new Menu(301, 'Edit Currency Rates', '/crm/currency/currency-spread-sheat', null, 'fa fa-cogs', null, false, 3),
    new Menu(302, 'Configure Schedule', '/crm/currency/currency-update', null, 'fa fa-cogs', null, false, 3),
    new Menu(303, 'Currencies Rate Board', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(304, 'Change Manual Rates', null, null, 'fa fa-cogs', null, false, 3),

    new Menu(1201, 'Products Grid', '/crm/products/grid', null, 'fa fa-cogs', null, false, 12),
    new Menu(1202, 'Tax Declaration', '/crm/products/tax-declaration', null, 'fa fa-cogs', null, false, 12),

    new Menu(700, 'AML Settings', '/crm/compliance/aml-settings', null, 'fa fa-tasks', null, false, 7),
    new Menu(701, 'AML Rules', '/crm/compliance/rules-grid', null, 'fa fa-tasks', null, false, 7),
    new Menu(702, 'KYC Review Log', '/crm/compliance/kyc-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(703, 'AML Review Log', '/crm/compliance/aml-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(704, 'Sanction List Log', '/crm/compliance/sanction-list-log', null, 'fa fa-history', null, false, 7),
    new Menu(705, 'Manage Sanction List', '/crm/compliance/manage-sanction-list', null, 'fa fa-tasks', null, false, 7),
    new Menu(706, 'AML Alerts', '/crm/compliance/aml-alerts', null, 'fa fa-bell-o', null, false, 7),
    new Menu(707, 'Audit Query Editor', '/crm/compliance/audit-query', null, 'fa fa-bell-o', null, false, 7),
    new Menu(708, 'Check Against Sanctionlist', '/crm/compliance/check-against-sanctionlist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(709, 'Whitelist Customers', '/crm/compliance/whitelist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(710, 'Occupation', '/crm/compliance/occupation', null, 'fa fa-bell-o', null, false, 7),
    new Menu(711, 'Industry', '/crm/compliance/industry', null, 'fa fa-bell-o', null, false, 7),

    new Menu(200, 'Individuals', '/crm/manage/individual', null, 'fa fa-user', null, false, 2),
    new Menu(201, 'Companies', '/crm/manage/company', null, 'fa fa-user', null, false, 2),
    new Menu(202, 'Links', '/crm/manage/links', null, 'fa fa-user', null, false, 2),
    new Menu(203, 'Third Parties', '/crm/manage/third-parties', null, 'fa fa-user', null, false, 2),

    new Menu(1000, 'Company Profile', '/crm/admin/owner-profile', null, 'fa fa-tasks', null, false, 10),
    new Menu(1001, 'Roles', '/crm/admin/roles', null, 'fa fa-users', null, false, 10),
    new Menu(1002, 'Branches', '/crm/admin/branches', null, 'fa fa-home', null, false, 10),
    new Menu(1003, 'Users', '/crm/admin/users', null, 'fa fa-users', null, false, 10),
    new Menu(1004, 'Agents', '/crm/admin/agents', null, 'fa fa-users', null, false, 10),
    new Menu(1005, 'Lookups', '/crm/admin/lookups', null, 'fa fa-users', null, false, 10),
    new Menu(1006, 'Licensing', '/crm/admin/licensing', null, 'fa fa-address-card-o', null, false, 10),
    new Menu(1007, 'Denominations', '/crm/admin/denominations', null, 'fa fa-money', null, false, 10),
    new Menu(1008, 'Preferences', '/crm/admin/server-preferences', null, 'fa fa-cogs', null, false, 10),
    new Menu(1009, 'Key Values', '/crm/admin/key-values', null, 'fa fa-cogs', null, false, 10),
    new Menu(1010, 'Fee Rules', '/crm/admin/fee-rules', null, 'fa fa-tasks', null, false, 10),
    new Menu(1011, 'Configure Rate Board', '/crm/admin/currency-rate-board', null, 'fa fa-cogs', null, false, 10),
    new Menu(1012, 'Date Range', '/crm/admin/date-range', null, 'fa fa-tasks', null, false, 10),
    new Menu(1013, 'Merge Accounts', null, null, 'fa fa-files-o', null, false, 10),
    // new Menu(1014, 'Database Backup', '/crm/admin/database-backup', null, 'fa fa-tasks', null, true, 10),
    new Menu(1015, 'Adjustments', '/crm/admin/adjustments', null, 'fa fa-tasks', null, false, 10),
    new Menu(1016, 'Power BI Dashboard', '/crm/admin/power-bi-dashboard', null, 'fa fa-tasks', null, false, 10),
    new Menu(1017, 'Delete All Transactions', null, null, 'fa fa-tasks', null, false, 10),

    new Menu(800, 'Fetch eWire', '/crm/ewires/fetch-e-wire', null, 'fa fa-files-o', null, false, 8),
    new Menu(801, 'Remote eWires', '/crm/ewires/remote-e-wires', null, 'fa fa-files-o', null, false, 8),
    new Menu(802, 'Pending eWires', '/crm/ewires/pending-ewire-transactions', null, 'fa fa-files-o', null, false, 8),

    new Menu(400, 'Invoices', '/crm/transactions/invoice', null, 'fa fa-files-o', null, false, 4),
    new Menu(401, 'Registers', '/crm/transactions/registers', null, 'fa fa-files-o', null, false, 4),
    new Menu(402, 'Advanced Pivot Table', '/crm/transactions/wpt', null, 'fa fa-files-o', null, false, 4),
    new Menu(403, 'Registers Pivot', '/crm/transactions/registers-pivot', null, 'fa fa-files-o', null, false, 4),
    new Menu(404, 'Cash Transactions', '/crm/transactions/cash-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(413, 'Crypto Transactions', '/crm/transactions/crypto-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(405, 'Account Transactions', '/crm/transactions/account-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(406, 'Wire Transactions', '/crm/transactions/wire-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(407, 'eWire Transactions', '/crm/ewires/ewire-transactions', null, 'fa fa-files-o', null, false, 8),
    new Menu(408, 'Cheque Transactions', '/crm/transactions/cheque-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(409, 'Wire Templates', '/crm/transactions/wire-templates', null, 'fa fa-files-o', null, false, 4),
    new Menu(410, 'Note Templates', '/crm/transactions/note-template', null, 'fa fa-files-o', null, false, 4),
    new Menu(411, 'Main Accounts', '/crm/transactions/main-accounts', null, 'fa fa-money', null, false, 4),
    new Menu(412, 'Accounts', '/crm/transactions/accounts', null, 'fa fa-money', null, false, 4),

    new Menu(600, 'Top 10 Selling Currencies', '/crm/reports/management/top-ten-sales', null, 'fa fa-files-o', null, false, 6),
    new Menu(601, 'Top 10 Customers', '/crm/reports/management/top-ten-customers', null, 'fa fa-files-o', null, false, 6),
    new Menu(602, 'Users Performance', '/crm/reports/management/users-performance', null, 'fa fa-files-o', null, false, 6),
    new Menu(603, 'Currency Buy & Sell', '/crm/reports/management/buy-and-sell', null, 'fa fa-files-o', null, false, 6),
    new Menu(604, 'Branch Performance', '/crm/reports/management/branch-performance-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(605, 'Monthly Summary', '/crm/reports/management/annual-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(606, 'Performance by Weekdays', '/crm/reports/management/weekly-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(607, 'Inventory by Branch', '/crm/reports/management/inventory-by-branch', null, 'fa fa-files-o', null, false, 6),
    new Menu(608, 'Z Report', '/crm/reports/management/z-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(609, 'Y Report', '/crm/reports/management/y-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(610, 'Expense Report', '/crm/reports/management/expense-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(611, 'Agent Account Report', '/crm/reports/management/agents-account-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(612, 'Trial Balance', '/crm/reports/financial/trial-balance', null, 'fa fa-files-o', null, false, 6),
    new Menu(613, 'PL Statement', '/crm/reports/financial/pl-statement', null, 'fa fa-files-o', null, false, 6),
    new Menu(614, 'Customer Report', '/crm/reports/customer-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(615, 'Holding Report', '/crm/reports/management/holding-report', null, 'fa fa-files-o', null, false, 6),

    new Menu(500, 'Tills', '/crm/teller/tills', null, 'fa fa-money', null, false, 5),
    new Menu(501, 'Teller Proof', '/crm/teller/teller-proof', null, 'fa fa-files-o', null, false, 5),
    new Menu(502, 'Teller Proof Lines', '/crm/teller/teller-proof-lines', null, 'fa fa-files-o', null, false, 5),
    new Menu(503, 'Transfer between Tills', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(504, 'Till Summary', '/crm/teller/till-summary', null, 'fa fa-files-o', null, false, 5),
    new Menu(505, 'Till Report', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(506, 'Teller Proof Report', '/crm/teller/teller-report', null, 'fa fa-files-o', null, false, 5),
    new Menu(507, 'Individual Teller Proof Report', '/crm/teller/individual-teller-report', null, 'fa fa-files-o', null, false, 5),

    new Menu(900, 'Orders Grid', '/crm/orders/orders-grid', null, 'fa fa-files-o', null, false, 9),
    new Menu(901, 'Order Lines', '/crm/orders/order-lines', null, 'fa fa-files-o', null, false, 9),
    new Menu(902, 'Ordering Customer', '/crm/orders/ordering-customers', null, 'fa fa-files-o', null, false, 9),
    new Menu(903, 'Order Log', '/crm/orders/order-exceptions', null, 'fa fa-files-o', null, false, 9),

];
export const horizontalMenuItems = [
    new Menu(1, '🏠', '/crm/dashboard', null, '', null, false, 0, true),
    new Menu(2, 'Contacts', '/crm/manage', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Currencies', '/crm/currency', null, 'fa fa-money', null, true, 0),
    new Menu(12, 'Products', '/crm/products', null, 'fa fa-user', null, true, 0),
    new Menu(11, 'Bookings', '/crm/bookings', null, 'fa fa-user', null, false, 0),
    new Menu(4, 'Modules', '/crm/transactions', null, 'fa fa-files-o', null, true, 0),
    new Menu(9, 'Orders', '/crm/orders', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(8, 'eWire', '/crm/ewires', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(5, 'Teller', '/crm/teller', null, 'fa fa-money', null, true, 0),
    new Menu(6, 'Reports', '/crm/reports', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(7, 'AML', '/crm/compliance', null, 'fa fa-tasks', null, true, 0),
    new Menu(10, 'Admin', '/crm/admin', null, 'fa fa-user', null, true, 0),

    new Menu(300, 'Currency Grid', '/crm/currency/currency-grid', null, 'fa fa-tasks', null, false, 3),
    new Menu(301, 'Edit Currency Rates', '/crm/currency/currency-spread-sheat', null, 'fa fa-cogs', null, false, 3),
    new Menu(302, 'Configure Schedule', '/crm/currency/currency-update', null, 'fa fa-cogs', null, false, 3),
    new Menu(303, 'Currencies Rate Board', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(304, 'Change Manual Rates', null, null, 'fa fa-cogs', null, false, 3),

    new Menu(1201, 'Products Grid', '/crm/products/grid', null, 'fa fa-cogs', null, false, 12),
    new Menu(1202, 'Tax Declaration', '/crm/products/tax-declaration', null, 'fa fa-cogs', null, false, 12),

    new Menu(700, 'AML Settings', '/crm/compliance/aml-settings', null, 'fa fa-tasks', null, false, 7),
    new Menu(701, 'AML Rules', '/crm/compliance/rules-grid', null, 'fa fa-tasks', null, false, 7),
    new Menu(702, 'KYC Review Log', '/crm/compliance/kyc-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(703, 'AML Review Log', '/crm/compliance/aml-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(704, 'Sanction List Log', '/crm/compliance/sanction-list-log', null, 'fa fa-history', null, false, 7),
    new Menu(705, 'Manage Sanction List', '/crm/compliance/manage-sanction-list', null, 'fa fa-tasks', null, false, 7),
    new Menu(706, 'AML Alerts', '/crm/compliance/aml-alerts', null, 'fa fa-bell-o', null, false, 7),
    new Menu(707, 'Audit Query Editor', '/crm/compliance/audit-query', null, 'fa fa-bell-o', null, false, 7),
    new Menu(708, 'Check Against Sanctionlist', '/crm/compliance/check-against-sanctionlist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(709, 'Whitelist Customers', '/crm/compliance/whitelist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(710, 'Occupation', '/crm/compliance/occupation', null, 'fa fa-bell-o', null, false, 7),
    new Menu(711, 'Industry', '/crm/compliance/industry', null, 'fa fa-bell-o', null, false, 7),

    new Menu(200, 'Individuals', '/crm/manage/individual', null, 'fa fa-user', null, false, 2),
    new Menu(201, 'Companies', '/crm/manage/company', null, 'fa fa-user', null, false, 2),
    new Menu(202, 'Links', '/crm/manage/links', null, 'fa fa-user', null, false, 2),
    new Menu(203, 'Third Parties', '/crm/manage/third-parties', null, 'fa fa-user', null, false, 2),

    new Menu(1000, 'Company Profile', '/crm/admin/owner-profile', null, 'fa fa-tasks', null, false, 10),
    new Menu(1001, 'Roles', '/crm/admin/roles', null, 'fa fa-users', null, false, 10),
    new Menu(1002, 'Branches', '/crm/admin/branches', null, 'fa fa-home', null, false, 10),
    new Menu(1003, 'Users', '/crm/admin/users', null, 'fa fa-users', null, false, 10),
    new Menu(1004, 'Agents', '/crm/admin/agents', null, 'fa fa-users', null, false, 10),
    new Menu(1005, 'Lookups', '/crm/admin/lookups', null, 'fa fa-users', null, false, 10),
    new Menu(1006, 'Licensing', '/crm/admin/licensing', null, 'fa fa-address-card-o', null, false, 10),
    new Menu(1007, 'Denominations', '/crm/admin/denominations', null, 'fa fa-money', null, false, 10),
    new Menu(1008, 'Preferences', '/crm/admin/server-preferences', null, 'fa fa-cogs', null, false, 10),
    new Menu(1009, 'Key Values', '/crm/admin/key-values', null, 'fa fa-cogs', null, false, 10),
    new Menu(1010, 'Fee Rules', '/crm/admin/fee-rules', null, 'fa fa-tasks', null, false, 10),
    new Menu(1011, 'Configure Rate Board', '/crm/admin/currency-rate-board', null, 'fa fa-cogs', null, false, 10),
    new Menu(1012, 'Date Range', '/crm/admin/date-range', null, 'fa fa-tasks', null, false, 10),
    new Menu(1013, 'Merge Accounts', null, null, 'fa fa-files-o', null, false, 10),
    // new Menu(1014, 'Database Backup', '/crm/admin/database-backup', null, 'fa fa-tasks', null, true, 10),
    new Menu(1015, 'Adjustments', '/crm/admin/adjustments', null, 'fa fa-tasks', null, false, 10),
    new Menu(1016, 'Power BI Dashboard', '/crm/admin/power-bi-dashboard', null, 'fa fa-tasks', null, false, 10),
    new Menu(1017, 'Delete All Transactions', null, null, 'fa fa-tasks', null, false, 10),

    new Menu(800, 'Fetch eWire', '/crm/ewires/fetch-e-wire', null, 'fa fa-files-o', null, false, 8),
    new Menu(801, 'Remote eWires', '/crm/ewires/remote-e-wires', null, 'fa fa-files-o', null, false, 8),
    new Menu(802, 'Pending eWires', '/crm/ewires/pending-ewire-transactions', null, 'fa fa-files-o', null, false, 8),

    new Menu(400, 'Invoices', '/crm/transactions/invoice', null, 'fa fa-files-o', null, false, 4),
    new Menu(401, 'Registers', '/crm/transactions/registers', null, 'fa fa-files-o', null, false, 4),
    new Menu(402, 'Advanced Pivot Table', '/crm/transactions/wpt', null, 'fa fa-files-o', null, false, 4),
    new Menu(403, 'Registers Pivot', '/crm/transactions/registers-pivot', null, 'fa fa-files-o', null, false, 4),
    new Menu(404, 'Cash Transactions', '/crm/transactions/cash-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(413, 'Crypto Transactions', '/crm/transactions/crypto-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(405, 'Account Transactions', '/crm/transactions/account-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(406, 'Wire Transactions', '/crm/transactions/wire-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(407, 'eWire Transactions', '/crm/ewires/ewire-transactions', null, 'fa fa-files-o', null, false, 8),
    new Menu(408, 'Cheque Transactions', '/crm/transactions/cheque-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(409, 'Wire Templates', '/crm/transactions/wire-templates', null, 'fa fa-files-o', null, false, 4),
    new Menu(410, 'Note Templates', '/crm/transactions/note-template', null, 'fa fa-files-o', null, false, 4),
    new Menu(411, 'Main Accounts', '/crm/transactions/main-accounts', null, 'fa fa-money', null, false, 4),
    new Menu(412, 'Accounts', '/crm/transactions/accounts', null, 'fa fa-money', null, false, 4),

    new Menu(600, 'Top 10 Selling Currencies', '/crm/reports/management/top-ten-sales', null, 'fa fa-files-o', null, false, 6),
    new Menu(601, 'Top 10 Customers', '/crm/reports/management/top-ten-customers', null, 'fa fa-files-o', null, false, 6),
    new Menu(602, 'Users Performance', '/crm/reports/management/users-performance', null, 'fa fa-files-o', null, false, 6),
    new Menu(603, 'Currency Buy & Sell', '/crm/reports/management/buy-and-sell', null, 'fa fa-files-o', null, false, 6),
    new Menu(604, 'Branch Performance', '/crm/reports/management/branch-performance-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(605, 'Monthly Summary', '/crm/reports/management/annual-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(606, 'Performance by Weekdays', '/crm/reports/management/weekly-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(607, 'Inventory by Branch', '/crm/reports/management/inventory-by-branch', null, 'fa fa-files-o', null, false, 6),
    new Menu(608, 'Z Report', '/crm/reports/management/z-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(609, 'Y Report', '/crm/reports/management/y-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(610, 'Expense Report', '/crm/reports/management/expense-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(611, 'Agent Account Report', '/crm/reports/management/agents-account-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(612, 'Trial Balance', '/crm/reports/financial/trial-balance', null, 'fa fa-files-o', null, false, 6),
    new Menu(613, 'PL Statement', '/crm/reports/financial/pl-statement', null, 'fa fa-files-o', null, false, 6),
    new Menu(614, 'Customer Report', '/crm/reports/customer-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(615, 'Holding Report', '/crm/reports/management/holding-report', null, 'fa fa-files-o', null, false, 6),

    new Menu(500, 'Tills', '/crm/teller/tills', null, 'fa fa-money', null, false, 5),
    new Menu(501, 'Teller Proof', '/crm/teller/teller-proof', null, 'fa fa-files-o', null, false, 5),
    new Menu(502, 'Teller Proof Lines', '/crm/teller/teller-proof-lines', null, 'fa fa-files-o', null, false, 5),
    new Menu(503, 'Transfer between Tills', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(504, 'Till Summary', '/crm/teller/till-summary', null, 'fa fa-files-o', null, false, 5),
    new Menu(505, 'Till Report', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(506, 'Teller Proof Report', '/crm/teller/teller-report', null, 'fa fa-files-o', null, false, 5),
    new Menu(507, 'Individual Teller Proof Report', '/crm/teller/individual-teller-report', null, 'fa fa-files-o', null, false, 5),

    new Menu(900, 'Orders Grid', '/crm/orders/orders-grid', null, 'fa fa-files-o', null, false, 9),
    new Menu(901, 'Order Lines', '/crm/orders/order-lines', null, 'fa fa-files-o', null, false, 9),
    new Menu(902, 'Ordering Customer', '/crm/orders/ordering-customers', null, 'fa fa-files-o', null, false, 9),
    new Menu(903, 'Order Log', '/crm/orders/order-exceptions', null, 'fa fa-files-o', null, false, 9),

];

export const verticalAgentMenuItems = [
    new Menu(1, '🏠', '/crm/dashboard', null, '', null, false, 0, true),
    new Menu(2, 'Contacts', '/crm/manage', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Currencies', '/crm/currency', null, 'fa fa-money', null, true, 0),
    new Menu(12, 'Products', '/crm/products', null, 'fa fa-user', null, true, 0),
    new Menu(11, 'Bookings', '/crm/bookings', null, 'fa fa-user', null, false, 0),
    new Menu(4, 'Modules', '/crm/transactions', null, 'fa fa-files-o', null, true, 0),
    new Menu(9, 'Orders', '/crm/orders', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(8, 'eWire', '/crm/ewires', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(5, 'Teller', '/crm/teller', null, 'fa fa-money', null, true, 0),
    new Menu(6, 'Reports', '/crm/reports', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(7, 'AML', '/crm/compliance', null, 'fa fa-tasks', null, true, 0),
    new Menu(10, 'Admin', '/crm/admin', null, 'fa fa-user', null, true, 0),

    new Menu(300, 'Currency Grid', '/crm/currency/currency-grid', null, 'fa fa-tasks', null, false, 3),
    new Menu(301, 'Edit Currency Rates', '/crm/currency/currency-spread-sheat', null, 'fa fa-cogs', null, false, 3),
    new Menu(302, 'Configure Schedule', '/crm/currency/currency-update', null, 'fa fa-cogs', null, false, 3),
    new Menu(303, 'Currencies Rate Board', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(304, 'Change Manual Rates', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(305, 'Tax Declaration', '/crm/currency/tax-declaration', null, 'fa fa-cogs', null, false, 3),

    new Menu(1201, 'Products Grid', '/crm/products/grid', null, 'fa fa-cogs', null, false, 12),
    new Menu(1202, 'Tax Declaration', '/crm/products/tax-declaration', null, 'fa fa-cogs', null, false, 12),

    new Menu(700, 'AML Settings', '/crm/compliance/aml-settings', null, 'fa fa-tasks', null, false, 7),
    new Menu(701, 'AML Rules', '/crm/compliance/rules-grid', null, 'fa fa-tasks', null, false, 7),
    new Menu(702, 'KYC Review Log', '/crm/compliance/kyc-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(703, 'AML Review Log', '/crm/compliance/aml-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(704, 'Sanction List Log', '/crm/compliance/sanction-list-log', null, 'fa fa-history', null, false, 7),
    new Menu(705, 'Manage Sanction List', '/crm/compliance/manage-sanction-list', null, 'fa fa-tasks', null, false, 7),
    new Menu(706, 'AML Alerts', '/crm/compliance/aml-alerts', null, 'fa fa-bell-o', null, false, 7),
    new Menu(707, 'Audit Query Editor', '/crm/compliance/audit-query', null, 'fa fa-bell-o', null, false, 7),
    new Menu(708, 'Check Against Sanctionlist', '/crm/compliance/check-against-sanctionlist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(709, 'Whitelist Customers', '/crm/compliance/whitelist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(710, 'Occupation', '/crm/compliance/occupation', null, 'fa fa-bell-o', null, false, 7),
    new Menu(711, 'Industry', '/crm/compliance/industry', null, 'fa fa-bell-o', null, false, 7),

    new Menu(200, 'Individuals', '/crm/manage/individual', null, 'fa fa-user', null, false, 2),
    new Menu(201, 'Companies', '/crm/manage/company', null, 'fa fa-user', null, false, 2),
    new Menu(202, 'Links', '/crm/manage/links', null, 'fa fa-user', null, false, 2),
    new Menu(203, 'Third Parties', '/crm/manage/third-parties', null, 'fa fa-user', null, false, 2),

    new Menu(1005, 'Lookups', '/crm/admin/lookups', null, 'fa fa-users', null, false, 10),
    new Menu(1007, 'Denominations', '/crm/admin/denominations', null, 'fa fa-money', null, false, 10),
    new Menu(1008, 'Preferences', '/crm/admin/server-preferences', null, 'fa fa-cogs', null, false, 10),
    new Menu(1009, 'Key Values', '/crm/admin/key-values', null, 'fa fa-cogs', null, false, 10),
    new Menu(1010, 'Fee Rules', '/crm/admin/fee-rules', null, 'fa fa-tasks', null, false, 10),
    new Menu(1011, 'Configure Rate Board', '/crm/admin/currency-rate-board', null, 'fa fa-cogs', null, false, 10),
    new Menu(1012, 'Date Range', '/crm/admin/date-range', null, 'fa fa-tasks', null, false, 10),
    new Menu(1013, 'Merge Accounts', null, null, 'fa fa-files-o', null, false, 10),
    new Menu(1015, 'Adjustments', '/crm/admin/adjustments', null, 'fa fa-tasks', null, false, 10),
    new Menu(1017, 'Delete All Transactions', null, null, 'fa fa-tasks', null, false, 10),

    new Menu(800, 'Fetch eWire', '/crm/ewires/fetch-e-wire', null, 'fa fa-files-o', null, false, 8),
    new Menu(407, 'eWire Transactions', '/crm/ewires/ewire-transactions', null, 'fa fa-files-o', null, false, 8),

    new Menu(400, 'Invoices', '/crm/transactions/invoice', null, 'fa fa-files-o', null, false, 4),
    new Menu(401, 'Registers', '/crm/transactions/registers', null, 'fa fa-files-o', null, false, 4),
    new Menu(402, 'Advanced Pivot Table', '/crm/transactions/wpt', null, 'fa fa-files-o', null, false, 4),
    new Menu(403, 'Registers Pivot', '/crm/transactions/registers-pivot', null, 'fa fa-files-o', null, false, 4),
    new Menu(404, 'Cash Transactions', '/crm/transactions/cash-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(413, 'Crypto Transactions', '/crm/transactions/crypto-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(405, 'Account Transactions', '/crm/transactions/account-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(406, 'Wire Transactions', '/crm/transactions/wire-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(408, 'Cheque Transactions', '/crm/transactions/cheque-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(409, 'Wire Templates', '/crm/transactions/wire-templates', null, 'fa fa-files-o', null, false, 4),
    new Menu(410, 'Note Templates', '/crm/transactions/note-template', null, 'fa fa-files-o', null, false, 4),
    new Menu(411, 'Main Accounts', '/crm/transactions/main-accounts', null, 'fa fa-money', null, false, 4),
    new Menu(412, 'Accounts', '/crm/transactions/accounts', null, 'fa fa-money', null, false, 4),

    new Menu(600, 'Top 10 Selling Currencies', '/crm/reports/management/top-ten-sales', null, 'fa fa-files-o', null, false, 6),
    new Menu(601, 'Top 10 Customers', '/crm/reports/management/top-ten-customers', null, 'fa fa-files-o', null, false, 6),
    new Menu(602, 'Users Performance', '/crm/reports/management/users-performance', null, 'fa fa-files-o', null, false, 6),
    new Menu(603, 'Currency Buy & Sell', '/crm/reports/management/buy-and-sell', null, 'fa fa-files-o', null, false, 6),
    new Menu(605, 'Monthly Summary', '/crm/reports/management/annual-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(606, 'Performance by Weekdays', '/crm/reports/management/weekly-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(607, 'Inventory by Branch', '/crm/reports/management/inventory-by-branch', null, 'fa fa-files-o', null, false, 6),
    new Menu(608, 'Z Report', '/crm/reports/management/z-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(609, 'Y Report', '/crm/reports/management/y-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(610, 'Expense Report', '/crm/reports/management/expense-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(612, 'Trial Balance', '/crm/reports/financial/trial-balance', null, 'fa fa-files-o', null, false, 6),
    new Menu(613, 'PL Statement', '/crm/reports/financial/pl-statement', null, 'fa fa-files-o', null, false, 6),
    new Menu(614, 'Customer Report', '/crm/reports/customer-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(615, 'Holding Report', '/crm/reports/management/holding-report', null, 'fa fa-files-o', null, false, 6),

    new Menu(500, 'Tills', '/crm/teller/tills', null, 'fa fa-money', null, false, 5),
    new Menu(501, 'Teller Proof', '/crm/teller/teller-proof', null, 'fa fa-files-o', null, false, 5),
    new Menu(502, 'Teller Proof Lines', '/crm/teller/teller-proof-lines', null, 'fa fa-files-o', null, false, 5),
    new Menu(503, 'Transfer between Tills', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(504, 'Till Summary', '/crm/teller/till-summary', null, 'fa fa-files-o', null, false, 5),
    new Menu(505, 'Till Report', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(506, 'Teller Proof Report', '/crm/teller/teller-report', null, 'fa fa-files-o', null, false, 5),
    new Menu(507, 'Individual Teller Proof Report', '/crm/teller/individual-teller-report', null, 'fa fa-files-o', null, false, 5),

    new Menu(900, 'Orders Grid', '/crm/orders/orders-grid', null, 'fa fa-files-o', null, false, 9),
    new Menu(901, 'Order Lines', '/crm/orders/order-lines', null, 'fa fa-files-o', null, false, 9),
    new Menu(902, 'Ordering Customer', '/crm/orders/ordering-customers', null, 'fa fa-files-o', null, false, 9),
    new Menu(903, 'Order Log', '/crm/orders/order-exceptions', null, 'fa fa-files-o', null, false, 9)

];

export const horizontalAgentMenuItems = [
    new Menu(1, '🏠', '/crm/dashboard', null, '', null, false, 0, true),
    new Menu(2, 'Contacts', '/crm/manage', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Currencies', '/crm/currency', null, 'fa fa-money', null, true, 0),
    new Menu(12, 'Products', '/crm/products', null, 'fa fa-user', null, true, 0),
    new Menu(11, 'Bookings', '/crm/bookings', null, 'fa fa-user', null, false, 0),
    new Menu(4, 'Modules', '/crm/transactions', null, 'fa fa-files-o', null, true, 0),
    new Menu(9, 'Orders', '/crm/orders', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(8, 'eWire', '/crm/ewires', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(5, 'Teller', '/crm/teller', null, 'fa fa-money', null, true, 0),
    new Menu(6, 'Reports', '/crm/reports', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(7, 'AML', '/crm/compliance', null, 'fa fa-tasks', null, true, 0),
    new Menu(10, 'Admin', '/crm/admin', null, 'fa fa-user', null, true, 0),

    new Menu(300, 'Currency Grid', '/crm/currency/currency-grid', null, 'fa fa-tasks', null, false, 3),
    new Menu(301, 'Edit Currency Rates', '/crm/currency/currency-spread-sheat', null, 'fa fa-cogs', null, false, 3),
    new Menu(302, 'Configure Schedule', '/crm/currency/currency-update', null, 'fa fa-cogs', null, false, 3),
    new Menu(303, 'Currencies Rate Board', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(304, 'Change Manual Rates', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(305, 'Tax Declaration', '/crm/currency/tax-declaration', null, 'fa fa-cogs', null, false, 3),

    new Menu(1201, 'Products Grid', '/crm/products/grid', null, 'fa fa-cogs', null, false, 12),
    new Menu(1202, 'Tax Declaration', '/crm/products/tax-declaration', null, 'fa fa-cogs', null, false, 12),

    new Menu(700, 'AML Settings', '/crm/compliance/aml-settings', null, 'fa fa-tasks', null, false, 7),
    new Menu(701, 'AML Rules', '/crm/compliance/rules-grid', null, 'fa fa-tasks', null, false, 7),
    new Menu(702, 'KYC Review Log', '/crm/compliance/kyc-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(703, 'AML Review Log', '/crm/compliance/aml-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(704, 'Sanction List Log', '/crm/compliance/sanction-list-log', null, 'fa fa-history', null, false, 7),
    new Menu(705, 'Manage Sanction List', '/crm/compliance/manage-sanction-list', null, 'fa fa-tasks', null, false, 7),
    new Menu(706, 'AML Alerts', '/crm/compliance/aml-alerts', null, 'fa fa-bell-o', null, false, 7),
    new Menu(707, 'Audit Query Editor', '/crm/compliance/audit-query', null, 'fa fa-bell-o', null, false, 7),
    new Menu(708, 'Check Against Sanctionlist', '/crm/compliance/check-against-sanctionlist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(709, 'Whitelist Customers', '/crm/compliance/whitelist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(710, 'Occupation', '/crm/compliance/occupation', null, 'fa fa-bell-o', null, false, 7),
    new Menu(711, 'Industry', '/crm/compliance/industry', null, 'fa fa-bell-o', null, false, 7),

    new Menu(200, 'Individuals', '/crm/manage/individual', null, 'fa fa-user', null, false, 2),
    new Menu(201, 'Companies', '/crm/manage/company', null, 'fa fa-user', null, false, 2),
    new Menu(202, 'Links', '/crm/manage/links', null, 'fa fa-user', null, false, 2),
    new Menu(203, 'Third Parties', '/crm/manage/third-parties', null, 'fa fa-user', null, false, 2),

    new Menu(1005, 'Lookups', '/crm/admin/lookups', null, 'fa fa-users', null, false, 10),
    new Menu(1007, 'Denominations', '/crm/admin/denominations', null, 'fa fa-money', null, false, 10),
    new Menu(1008, 'Preferences', '/crm/admin/server-preferences', null, 'fa fa-cogs', null, false, 10),
    new Menu(1009, 'Key Values', '/crm/admin/key-values', null, 'fa fa-cogs', null, false, 10),
    new Menu(1010, 'Fee Rules', '/crm/admin/fee-rules', null, 'fa fa-tasks', null, false, 10),
    new Menu(1011, 'Configure Rate Board', '/crm/admin/currency-rate-board', null, 'fa fa-cogs', null, false, 10),
    new Menu(1012, 'Date Range', '/crm/admin/date-range', null, 'fa fa-tasks', null, false, 10),
    new Menu(1013, 'Merge Accounts', null, null, 'fa fa-files-o', null, false, 10),
    new Menu(1015, 'Adjustments', '/crm/admin/adjustments', null, 'fa fa-tasks', null, false, 10),
    new Menu(1017, 'Delete All Transactions', null, null, 'fa fa-tasks', null, false, 10),

    new Menu(800, 'Fetch eWire', '/crm/ewires/fetch-e-wire', null, 'fa fa-files-o', null, false, 8),
    new Menu(407, 'eWire Transactions', '/crm/ewires/ewire-transactions', null, 'fa fa-files-o', null, false, 8),

    new Menu(400, 'Invoices', '/crm/transactions/invoice', null, 'fa fa-files-o', null, false, 4),
    new Menu(401, 'Registers', '/crm/transactions/registers', null, 'fa fa-files-o', null, false, 4),
    new Menu(402, 'Advanced Pivot Table', '/crm/transactions/wpt', null, 'fa fa-files-o', null, false, 4),
    new Menu(403, 'Registers Pivot', '/crm/transactions/registers-pivot', null, 'fa fa-files-o', null, false, 4),
    new Menu(404, 'Cash Transactions', '/crm/transactions/cash-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(413, 'Crypto Transactions', '/crm/transactions/crypto-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(405, 'Account Transactions', '/crm/transactions/account-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(406, 'Wire Transactions', '/crm/transactions/wire-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(408, 'Cheque Transactions', '/crm/transactions/cheque-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(409, 'Wire Templates', '/crm/transactions/wire-templates', null, 'fa fa-files-o', null, false, 4),
    new Menu(410, 'Note Templates', '/crm/transactions/note-template', null, 'fa fa-files-o', null, false, 4),
    new Menu(411, 'Main Accounts', '/crm/transactions/main-accounts', null, 'fa fa-money', null, false, 4),
    new Menu(412, 'Accounts', '/crm/transactions/accounts', null, 'fa fa-money', null, false, 4),

    new Menu(600, 'Top 10 Selling Currencies', '/crm/reports/management/top-ten-sales', null, 'fa fa-files-o', null, false, 6),
    new Menu(601, 'Top 10 Customers', '/crm/reports/management/top-ten-customers', null, 'fa fa-files-o', null, false, 6),
    new Menu(602, 'Users Performance', '/crm/reports/management/users-performance', null, 'fa fa-files-o', null, false, 6),
    new Menu(603, 'Currency Buy & Sell', '/crm/reports/management/buy-and-sell', null, 'fa fa-files-o', null, false, 6),
    new Menu(605, 'Monthly Summary', '/crm/reports/management/annual-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(606, 'Performance by Weekdays', '/crm/reports/management/weekly-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(607, 'Inventory by Branch', '/crm/reports/management/inventory-by-branch', null, 'fa fa-files-o', null, false, 6),
    new Menu(608, 'Z Report', '/crm/reports/management/z-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(609, 'Y Report', '/crm/reports/management/y-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(610, 'Expense Report', '/crm/reports/management/expense-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(612, 'Trial Balance', '/crm/reports/financial/trial-balance', null, 'fa fa-files-o', null, false, 6),
    new Menu(613, 'PL Statement', '/crm/reports/financial/pl-statement', null, 'fa fa-files-o', null, false, 6),
    new Menu(614, 'Customer Report', '/crm/reports/customer-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(615, 'Holding Report', '/crm/reports/management/holding-report', null, 'fa fa-files-o', null, false, 6),

    new Menu(500, 'Tills', '/crm/teller/tills', null, 'fa fa-money', null, false, 5),
    new Menu(501, 'Teller Proof', '/crm/teller/teller-proof', null, 'fa fa-files-o', null, false, 5),
    new Menu(502, 'Teller Proof Lines', '/crm/teller/teller-proof-lines', null, 'fa fa-files-o', null, false, 5),
    new Menu(503, 'Transfer between Tills', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(504, 'Till Summary', '/crm/teller/till-summary', null, 'fa fa-files-o', null, false, 5),
    new Menu(505, 'Till Report', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(506, 'Teller Proof Report', '/crm/teller/teller-report', null, 'fa fa-files-o', null, false, 5),
    new Menu(507, 'Individual Teller Proof Report', '/crm/teller/individual-teller-report', null, 'fa fa-files-o', null, false, 5),

    new Menu(900, 'Orders Grid', '/crm/orders/orders-grid', null, 'fa fa-files-o', null, false, 9),
    new Menu(901, 'Order Lines', '/crm/orders/order-lines', null, 'fa fa-files-o', null, false, 9),
    new Menu(902, 'Ordering Customer', '/crm/orders/ordering-customers', null, 'fa fa-files-o', null, false, 9),
    new Menu(903, 'Order Log', '/crm/orders/order-exceptions', null, 'fa fa-files-o', null, false, 9)

];

export const verticalSuperAgentMenuItems = [
    new Menu(1, '🏠', '/crm/dashboard', null, '', null, false, 0, true),
    new Menu(2, 'Contacts', '/crm/manage', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Currencies', '/crm/currency', null, 'fa fa-money', null, true, 0),
    new Menu(12, 'Products', '/crm/products', null, 'fa fa-user', null, true, 0),
    new Menu(11, 'Bookings', '/crm/bookings', null, 'fa fa-user', null, false, 0),
    new Menu(4, 'Modules', '/crm/transactions', null, 'fa fa-files-o', null, true, 0),
    new Menu(9, 'Orders', '/crm/orders', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(8, 'eWire', '/crm/ewires', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(5, 'Teller', '/crm/teller', null, 'fa fa-money', null, true, 0),
    new Menu(6, 'Reports', '/crm/reports', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(7, 'AML', '/crm/compliance', null, 'fa fa-tasks', null, true, 0),
    new Menu(10, 'Admin', '/crm/admin', null, 'fa fa-user', null, true, 0),

    new Menu(300, 'Currency Grid', '/crm/currency/currency-grid', null, 'fa fa-tasks', null, false, 3),
    new Menu(301, 'Edit Currency Rates', '/crm/currency/currency-spread-sheat', null, 'fa fa-cogs', null, false, 3),
    new Menu(302, 'Configure Schedule', '/crm/currency/currency-update', null, 'fa fa-cogs', null, false, 3),
    new Menu(303, 'Currencies Rate Board', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(304, 'Change Manual Rates', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(305, 'Tax Declaration', '/crm/currency/tax-declaration', null, 'fa fa-cogs', null, false, 3),

    new Menu(1201, 'Products Grid', '/crm/products/grid', null, 'fa fa-cogs', null, false, 12),
    new Menu(1202, 'Tax Declaration', '/crm/products/tax-declaration', null, 'fa fa-cogs', null, false, 12),

    new Menu(700, 'AML Settings', '/crm/compliance/aml-settings', null, 'fa fa-tasks', null, false, 7),
    new Menu(701, 'AML Rules', '/crm/compliance/rules-grid', null, 'fa fa-tasks', null, false, 7),
    new Menu(702, 'KYC Review Log', '/crm/compliance/kyc-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(703, 'AML Review Log', '/crm/compliance/aml-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(704, 'Sanction List Log', '/crm/compliance/sanction-list-log', null, 'fa fa-history', null, false, 7),
    new Menu(705, 'Manage Sanction List', '/crm/compliance/manage-sanction-list', null, 'fa fa-tasks', null, false, 7),
    new Menu(706, 'AML Alerts', '/crm/compliance/aml-alerts', null, 'fa fa-bell-o', null, false, 7),
    new Menu(707, 'Audit Query Editor', '/crm/compliance/audit-query', null, 'fa fa-bell-o', null, false, 7),
    new Menu(708, 'Check Against Sanctionlist', '/crm/compliance/check-against-sanctionlist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(709, 'Whitelist Customers', '/crm/compliance/whitelist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(710, 'Occupation', '/crm/compliance/occupation', null, 'fa fa-bell-o', null, false, 7),
    new Menu(711, 'Industry', '/crm/compliance/industry', null, 'fa fa-bell-o', null, false, 7),

    new Menu(200, 'Individuals', '/crm/manage/individual', null, 'fa fa-user', null, false, 2),
    new Menu(201, 'Companies', '/crm/manage/company', null, 'fa fa-user', null, false, 2),
    new Menu(202, 'Links', '/crm/manage/links', null, 'fa fa-user', null, false, 2),
    new Menu(203, 'Third Parties', '/crm/manage/third-parties', null, 'fa fa-user', null, false, 2),

    new Menu(1005, 'Lookups', '/crm/admin/lookups', null, 'fa fa-users', null, false, 10),
    new Menu(1007, 'Denominations', '/crm/admin/denominations', null, 'fa fa-money', null, false, 10),
    new Menu(1008, 'Preferences', '/crm/admin/server-preferences', null, 'fa fa-cogs', null, false, 10),
    new Menu(1009, 'Key Values', '/crm/admin/key-values', null, 'fa fa-cogs', null, false, 10),
    new Menu(1010, 'Fee Rules', '/crm/admin/fee-rules', null, 'fa fa-tasks', null, false, 10),
    new Menu(1011, 'Configure Rate Board', '/crm/admin/currency-rate-board', null, 'fa fa-cogs', null, false, 10),
    new Menu(1012, 'Date Range', '/crm/admin/date-range', null, 'fa fa-tasks', null, false, 10),
    new Menu(1013, 'Merge Accounts', null, null, 'fa fa-files-o', null, false, 10),
    new Menu(1015, 'Adjustments', '/crm/admin/adjustments', null, 'fa fa-tasks', null, false, 10),
    new Menu(1016, 'Power BI Dashboard', '/crm/admin/power-bi-dashboard', null, 'fa fa-tasks', null, false, 10),
    new Menu(1017, 'Delete All Transactions', null, null, 'fa fa-tasks', null, false, 10),

    new Menu(800, 'Fetch eWire', '/crm/ewires/fetch-e-wire', null, 'fa fa-files-o', null, false, 8),
    new Menu(801, 'Remote eWires', '/crm/ewires/remote-e-wires', null, 'fa fa-files-o', null, false, 8),
    new Menu(802, 'Pending eWires', '/crm/ewires/pending-ewire-transactions', null, 'fa fa-files-o', null, false, 8),

    new Menu(400, 'Invoices', '/crm/transactions/invoice', null, 'fa fa-files-o', null, false, 4),
    new Menu(401, 'Registers', '/crm/transactions/registers', null, 'fa fa-files-o', null, false, 4),
    new Menu(402, 'Advanced Pivot Table', '/crm/transactions/wpt', null, 'fa fa-files-o', null, false, 4),
    new Menu(403, 'Registers Pivot', '/crm/transactions/registers-pivot', null, 'fa fa-files-o', null, false, 4),
    new Menu(404, 'Cash Transactions', '/crm/transactions/cash-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(413, 'Crypto Transactions', '/crm/transactions/crypto-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(405, 'Account Transactions', '/crm/transactions/account-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(406, 'Wire Transactions', '/crm/transactions/wire-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(407, 'eWire Transactions', '/crm/ewires/ewire-transactions', null, 'fa fa-files-o', null, false, 8),
    new Menu(408, 'Cheque Transactions', '/crm/transactions/cheque-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(409, 'Wire Templates', '/crm/transactions/wire-templates', null, 'fa fa-files-o', null, false, 4),
    new Menu(410, 'Note Templates', '/crm/transactions/note-template', null, 'fa fa-files-o', null, false, 4),
    new Menu(411, 'Main Accounts', '/crm/transactions/main-accounts', null, 'fa fa-money', null, false, 4),
    new Menu(412, 'Accounts', '/crm/transactions/accounts', null, 'fa fa-money', null, false, 4),

    new Menu(600, 'Top 10 Selling Currencies', '/crm/reports/management/top-ten-sales', null, 'fa fa-files-o', null, false, 6),
    new Menu(601, 'Top 10 Customers', '/crm/reports/management/top-ten-customers', null, 'fa fa-files-o', null, false, 6),
    new Menu(602, 'Users Performance', '/crm/reports/management/users-performance', null, 'fa fa-files-o', null, false, 6),
    new Menu(603, 'Currency Buy & Sell', '/crm/reports/management/buy-and-sell', null, 'fa fa-files-o', null, false, 6),
    new Menu(605, 'Monthly Summary', '/crm/reports/management/annual-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(606, 'Performance by Weekdays', '/crm/reports/management/weekly-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(607, 'Inventory by Branch', '/crm/reports/management/inventory-by-branch', null, 'fa fa-files-o', null, false, 6),
    new Menu(608, 'Z Report', '/crm/reports/management/z-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(609, 'Y Report', '/crm/reports/management/y-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(610, 'Expense Report', '/crm/reports/management/expense-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(612, 'Trial Balance', '/crm/reports/financial/trial-balance', null, 'fa fa-files-o', null, false, 6),
    new Menu(613, 'PL Statement', '/crm/reports/financial/pl-statement', null, 'fa fa-files-o', null, false, 6),
    new Menu(614, 'Customer Report', '/crm/reports/customer-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(615, 'Holding Report', '/crm/reports/management/holding-report', null, 'fa fa-files-o', null, false, 6),

    new Menu(500, 'Tills', '/crm/teller/tills', null, 'fa fa-money', null, false, 5),
    new Menu(501, 'Teller Proof', '/crm/teller/teller-proof', null, 'fa fa-files-o', null, false, 5),
    new Menu(502, 'Teller Proof Lines', '/crm/teller/teller-proof-lines', null, 'fa fa-files-o', null, false, 5),
    new Menu(503, 'Transfer between Tills', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(504, 'Till Summary', '/crm/teller/till-summary', null, 'fa fa-files-o', null, false, 5),
    new Menu(505, 'Till Report', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(506, 'Teller Proof Report', '/crm/teller/teller-report', null, 'fa fa-files-o', null, false, 5),
    new Menu(507, 'Individual Teller Proof Report', '/crm/teller/individual-teller-report', null, 'fa fa-files-o', null, false, 5),

    new Menu(900, 'Orders Grid', '/crm/orders/orders-grid', null, 'fa fa-files-o', null, false, 9),
    new Menu(901, 'Order Lines', '/crm/orders/order-lines', null, 'fa fa-files-o', null, false, 9),
    new Menu(902, 'Ordering Customer', '/crm/orders/ordering-customers', null, 'fa fa-files-o', null, false, 9),
    new Menu(903, 'Order Log', '/crm/orders/order-exceptions', null, 'fa fa-files-o', null, false, 9)

];
export const horizontalSuperAgentMenuItems = [
    new Menu(1, '🏠', '/crm/dashboard', null, '', null, false, 0, true),
    new Menu(2, 'Contacts', '/crm/manage', null, 'fa fa-tasks', null, true, 0),
    new Menu(3, 'Currencies', '/crm/currency', null, 'fa fa-money', null, true, 0),
    new Menu(12, 'Products', '/crm/products', null, 'fa fa-user', null, true, 0),
    new Menu(11, 'Bookings', '/crm/bookings', null, 'fa fa-user', null, false, 0),
    new Menu(4, 'Modules', '/crm/transactions', null, 'fa fa-files-o', null, true, 0),
    new Menu(9, 'Orders', '/crm/orders', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(8, 'eWire', '/crm/ewires', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(5, 'Teller', '/crm/teller', null, 'fa fa-money', null, true, 0),
    new Menu(6, 'Reports', '/crm/reports', null, 'fa fa-newspaper-o', null, true, 0),
    new Menu(7, 'AML', '/crm/compliance', null, 'fa fa-tasks', null, true, 0),
    new Menu(10, 'Admin', '/crm/admin', null, 'fa fa-user', null, true, 0),

    new Menu(300, 'Currency Grid', '/crm/currency/currency-grid', null, 'fa fa-tasks', null, false, 3),
    new Menu(301, 'Edit Currency Rates', '/crm/currency/currency-spread-sheat', null, 'fa fa-cogs', null, false, 3),
    new Menu(302, 'Configure Schedule', '/crm/currency/currency-update', null, 'fa fa-cogs', null, false, 3),
    new Menu(303, 'Currencies Rate Board', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(304, 'Change Manual Rates', null, null, 'fa fa-cogs', null, false, 3),
    new Menu(305, 'Tax Declaration', '/crm/currency/tax-declaration', null, 'fa fa-cogs', null, false, 3),

    new Menu(1201, 'Products Grid', '/crm/products/grid', null, 'fa fa-cogs', null, false, 12),
    new Menu(1202, 'Tax Declaration', '/crm/products/tax-declaration', null, 'fa fa-cogs', null, false, 12),

    new Menu(700, 'AML Settings', '/crm/compliance/aml-settings', null, 'fa fa-tasks', null, false, 7),
    new Menu(701, 'AML Rules', '/crm/compliance/rules-grid', null, 'fa fa-tasks', null, false, 7),
    new Menu(702, 'KYC Review Log', '/crm/compliance/kyc-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(703, 'AML Review Log', '/crm/compliance/aml-review-log', null, 'fa fa-tasks', null, false, 7),
    new Menu(704, 'Sanction List Log', '/crm/compliance/sanction-list-log', null, 'fa fa-history', null, false, 7),
    new Menu(705, 'Manage Sanction List', '/crm/compliance/manage-sanction-list', null, 'fa fa-tasks', null, false, 7),
    new Menu(706, 'AML Alerts', '/crm/compliance/aml-alerts', null, 'fa fa-bell-o', null, false, 7),
    new Menu(707, 'Audit Query Editor', '/crm/compliance/audit-query', null, 'fa fa-bell-o', null, false, 7),
    new Menu(708, 'Check Against Sanctionlist', '/crm/compliance/check-against-sanctionlist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(709, 'Whitelist Customers', '/crm/compliance/whitelist', null, 'fa fa-bell-o', null, false, 7),
    new Menu(710, 'Occupation', '/crm/compliance/occupation', null, 'fa fa-bell-o', null, false, 7),
    new Menu(711, 'Industry', '/crm/compliance/industry', null, 'fa fa-bell-o', null, false, 7),

    new Menu(200, 'Individuals', '/crm/manage/individual', null, 'fa fa-user', null, false, 2),
    new Menu(201, 'Companies', '/crm/manage/company', null, 'fa fa-user', null, false, 2),
    new Menu(202, 'Links', '/crm/manage/links', null, 'fa fa-user', null, false, 2),
    new Menu(203, 'Third Parties', '/crm/manage/third-parties', null, 'fa fa-user', null, false, 2),

    new Menu(1005, 'Lookups', '/crm/admin/lookups', null, 'fa fa-users', null, false, 10),
    new Menu(1007, 'Denominations', '/crm/admin/denominations', null, 'fa fa-money', null, false, 10),
    new Menu(1008, 'Preferences', '/crm/admin/server-preferences', null, 'fa fa-cogs', null, false, 10),
    new Menu(1009, 'Key Values', '/crm/admin/key-values', null, 'fa fa-cogs', null, false, 10),
    new Menu(1010, 'Fee Rules', '/crm/admin/fee-rules', null, 'fa fa-tasks', null, false, 10),
    new Menu(1011, 'Configure Rate Board', '/crm/admin/currency-rate-board', null, 'fa fa-cogs', null, false, 10),
    new Menu(1012, 'Date Range', '/crm/admin/date-range', null, 'fa fa-tasks', null, false, 10),
    new Menu(1013, 'Merge Accounts', null, null, 'fa fa-files-o', null, false, 10),
    new Menu(1015, 'Adjustments', '/crm/admin/adjustments', null, 'fa fa-tasks', null, false, 10),
    new Menu(1016, 'Power BI Dashboard', '/crm/admin/power-bi-dashboard', null, 'fa fa-tasks', null, false, 10),
    new Menu(1017, 'Delete All Transactions', null, null, 'fa fa-tasks', null, false, 10),

    new Menu(800, 'Fetch eWire', '/crm/ewires/fetch-e-wire', null, 'fa fa-files-o', null, false, 8),
    new Menu(801, 'Remote eWires', '/crm/ewires/remote-e-wires', null, 'fa fa-files-o', null, false, 8),
    new Menu(802, 'Pending eWires', '/crm/ewires/pending-ewire-transactions', null, 'fa fa-files-o', null, false, 8),

    new Menu(400, 'Invoices', '/crm/transactions/invoice', null, 'fa fa-files-o', null, false, 4),
    new Menu(401, 'Registers', '/crm/transactions/registers', null, 'fa fa-files-o', null, false, 4),
    new Menu(402, 'Advanced Pivot Table', '/crm/transactions/wpt', null, 'fa fa-files-o', null, false, 4),
    new Menu(403, 'Registers Pivot', '/crm/transactions/registers-pivot', null, 'fa fa-files-o', null, false, 4),
    new Menu(404, 'Cash Transactions', '/crm/transactions/cash-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(413, 'Crypto Transactions', '/crm/transactions/crypto-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(405, 'Account Transactions', '/crm/transactions/account-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(406, 'Wire Transactions', '/crm/transactions/wire-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(407, 'eWire Transactions', '/crm/ewires/ewire-transactions', null, 'fa fa-files-o', null, false, 8),
    new Menu(408, 'Cheque Transactions', '/crm/transactions/cheque-transactions', null, 'fa fa-files-o', null, false, 4),
    new Menu(409, 'Wire Templates', '/crm/transactions/wire-templates', null, 'fa fa-files-o', null, false, 4),
    new Menu(410, 'Note Templates', '/crm/transactions/note-template', null, 'fa fa-files-o', null, false, 4),
    new Menu(411, 'Main Accounts', '/crm/transactions/main-accounts', null, 'fa fa-money', null, false, 4),
    new Menu(412, 'Accounts', '/crm/transactions/accounts', null, 'fa fa-money', null, false, 4),

    new Menu(600, 'Top 10 Selling Currencies', '/crm/reports/management/top-ten-sales', null, 'fa fa-files-o', null, false, 6),
    new Menu(601, 'Top 10 Customers', '/crm/reports/management/top-ten-customers', null, 'fa fa-files-o', null, false, 6),
    new Menu(602, 'Users Performance', '/crm/reports/management/users-performance', null, 'fa fa-files-o', null, false, 6),
    new Menu(603, 'Currency Buy & Sell', '/crm/reports/management/buy-and-sell', null, 'fa fa-files-o', null, false, 6),
    new Menu(605, 'Monthly Summary', '/crm/reports/management/annual-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(606, 'Performance by Weekdays', '/crm/reports/management/weekly-summary', null, 'fa fa-files-o', null, false, 6),
    new Menu(607, 'Inventory by Branch', '/crm/reports/management/inventory-by-branch', null, 'fa fa-files-o', null, false, 6),
    new Menu(608, 'Z Report', '/crm/reports/management/z-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(609, 'Y Report', '/crm/reports/management/y-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(610, 'Expense Report', '/crm/reports/management/expense-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(612, 'Trial Balance', '/crm/reports/financial/trial-balance', null, 'fa fa-files-o', null, false, 6),
    new Menu(613, 'PL Statement', '/crm/reports/financial/pl-statement', null, 'fa fa-files-o', null, false, 6),
    new Menu(614, 'Customer Report', '/crm/reports/customer-report', null, 'fa fa-files-o', null, false, 6),
    new Menu(615, 'Holding Report', '/crm/reports/management/holding-report', null, 'fa fa-files-o', null, false, 6),

    new Menu(500, 'Tills', '/crm/teller/tills', null, 'fa fa-money', null, false, 5),
    new Menu(501, 'Teller Proof', '/crm/teller/teller-proof', null, 'fa fa-files-o', null, false, 5),
    new Menu(502, 'Teller Proof Lines', '/crm/teller/teller-proof-lines', null, 'fa fa-files-o', null, false, 5),
    new Menu(503, 'Transfer between Tills', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(504, 'Till Summary', '/crm/teller/till-summary', null, 'fa fa-files-o', null, false, 5),
    new Menu(505, 'Till Report', null, null, 'fa fa-files-o', null, false, 5),
    new Menu(506, 'Teller Proof Report', '/crm/teller/teller-report', null, 'fa fa-files-o', null, false, 5),
    new Menu(507, 'Individual Teller Proof Report', '/crm/teller/individual-teller-report', null, 'fa fa-files-o', null, false, 5),

    new Menu(900, 'Orders Grid', '/crm/orders/orders-grid', null, 'fa fa-files-o', null, false, 9),
    new Menu(901, 'Order Lines', '/crm/orders/order-lines', null, 'fa fa-files-o', null, false, 9),
    new Menu(902, 'Ordering Customer', '/crm/orders/ordering-customers', null, 'fa fa-files-o', null, false, 9),
    new Menu(903, 'Order Log', '/crm/orders/order-exceptions', null, 'fa fa-files-o', null, false, 9)
];
