import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { RegisterCommentService } from 'projects/cxr-crm/src/app/pages/_helpers/register-comments';
import { PrintFormats } from 'src/app/_services/constants';
import { PrintService } from 'src/app/_services/print.service';
import { NumberFormatPipe } from 'src/app/shared/_pipes/number-format.pipe';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'cxr-print-invoice-preview',
  templateUrl: './print-invoice-preview.component.html',
  styleUrls: ['./print-invoice-preview.component.scss']
})
export class PrintInvoicePreviewComponent implements OnInit, AfterViewInit {
  InvoiceID: any;
  InvoiceExID: any;
  OwnerDetails = GetUserObject.GetUserObject();
  //Username = GetUserObject.GetUserFirstName();
  UserName;
  ownerLogo: string;// = '' GetUserObject.GetUserObject().LogoPath;
  InvoiceDate;
  ActualInvoiceDate;
  SequenceNumber;
  isTransfer;
  InvoiceTime;
  CustomerDetails;
  BranchDetails;
  invoiceDetails;
  invoiceData;
  PictureID: any;
  Rows = [];
  Receipient = [];
  isEWire: boolean = false;
  thermalPrint: boolean = false;
  thermalPrintWithLogo: boolean = false;
  standardWithCustomerDetails: boolean = false;
  eWireformat: boolean = false;
  standard: boolean = false;
  printFormat: number;
  cxrLogoPath = environment.deployUrl + "assets/images/clearviewsyslogo.png";
  DisclaimerText: string;
  Remarks: string;
  SourceOfFunds: string;
  PurposeOfTransaction: string;
  ClerkName: string;
  public showDenominations: boolean;
  public stamp = {
    show: false,
    color: '#ccc',
    message: 'Quote'
  };
  constructor (route: ActivatedRoute,
    private _location: Location,
    private _numberFormatPipe: NumberFormatPipe,
    private translate: TranslateService,
    private _printService: PrintService,
    public _registerCommentService: RegisterCommentService,
    private printService: PrintService) {
    //this.ownerLogo = GetUserObject.GetUserObject().LogoPath;
    this.InvoiceID = route.snapshot.params['InvoiceID'];
  }
  loadedImage() {
    if (this.invoiceData) {
      this.printService.onDataReady(this.stamp.show);
    }
  }
  error() {
    this.loadedImage();
  }

  ngAfterViewInit() {

    if (this.printFormat == PrintFormats.Thermal5InchFormat || this.printFormat == PrintFormats.NarrowPrint || this.printFormat == 8 || this.printFormat == PrintFormats.MiniReceipt) {
      this.loadedImage();
    }
  }
  public isThermal: boolean = false;
  async ngOnInit() {
    this.showDenominations = this.OwnerDetails.printDenominationsInReceipt;
    if (this.InvoiceID == 0) {
      const data = localStorage.getItem('InvoiceDetails');
      this.invoiceData = JSON.parse(data);
    }
    else {
      this.invoiceData = this.printService.invoiceDetails;
    }
    this.ownerLogo = this.invoiceData.OwnerLogo;
    if (!(this.invoiceData)) {
      this._location.back();
    }
    this.printFormat = this.invoiceData.format;
    if ([1, 2, 5, 7].indexOf(this.printFormat) >= 0) {
      this.isThermal = false;
    }
    else this.isThermal = true;
    // if ( == 1)
    //   this.standard = true;
    // else if (this.invoiceData.format == 2)
    //   this.standardWithCustomerDetails = true;
    // else if (this.invoiceData.format == 3)
    //   this.thermalPrint = true;
    // else if (this.invoiceData.format == 4)
    //   this.thermalPrintWithLogo = true;
    // else if (this.invoiceData.format == 5)
    //   this.eWireformat = true;



    this.OwnerDetails = this.invoiceData.OwnerDetails;
    this.InvoiceExID = this.invoiceData.InvoiceID;
    this.UserName = this.invoiceData.UserName;
    this.Rows = this.invoiceData.Rows;// (await this._registerCommentService.getLocalisedComments(this.invoiceData.Rows)).reverse();

    this.ClerkName = this.invoiceData.ClerkName;
    this.CustomerDetails = this.invoiceData.CustomerDetails;
    this.InvoiceDate = this.invoiceData.InvoiceDate;
    this.InvoiceTime = this.invoiceData.InvoiceTime;
    this.ActualInvoiceDate = this.invoiceData.ActualInvoiceDate;
    this.SequenceNumber = this.invoiceData.SequenceNumber;
    this.BranchDetails = this.invoiceData.BranchDetails;
    this.isTransfer = this.invoiceData.isTransfer;
    this.DisclaimerText = this.invoiceData.DisclaimerText;
    this.Remarks = this.invoiceData.Remarks;
    this.PictureID = this.invoiceData.PictureIDs ? this.invoiceData.PictureIDs[this.invoiceData.PictureIDs.length - 1] : null;
    this.SourceOfFunds = this.invoiceData.SourceOfFunds;
    this.PurposeOfTransaction = this.invoiceData.PurposeOfTransaction;

    this.Rows.forEach((value) => {
      if (value.isEWire) {
        this.isEWire = true;
        this.Receipient.push(value.SendEWireFormData?.BeneficiaryDetails);
      }
    });
    this.stamp.show = !this.invoiceData.InvoiceID;
  }



  // getInvoiceDetails() {
  //   const amount = this.printService.invoiceDetails;
  //   return new Promise(resolve =>
  //     setTimeout(() => resolve({ amount }), 5000)
  //   );
  // }

  fnReceivedString(x): string {
    var PaymentType = "";
    if (x.isCash) {
      PaymentType = "Cash";
    } else if (x.isAccount) {
      if (x.isTransfer && x.isReceived) {
        return "Transfered from Account";
      } else if (x.isTransfer && !x.isReceived) {
        return "Transfered to Account";
      } else {
        PaymentType = x.isReceived ? "to Account" : "from Account";
      }

    } else if (x.isCheque) {
      PaymentType = "Cheque";
    } else if (x.isWire) {
      PaymentType = "Wire";
    } else if (x.isEWire) {
      PaymentType = "eWire";
    }

    if (x.isReceived) {
      return "Bought " + PaymentType + ", from Customer";
    } else if (!x.isReceived) {
      return "Paid " + PaymentType + ", to Customer";
    }
    return null;
  }

  fnReceivedAmountString(x) {
    if (x.isReceived) {
      return this._numberFormatPipe.transform(x.In) + " " + x.Currency;
    } else if (!x.isReceived) {
      return this._numberFormatPipe.transform(x.Out) + " " + x.Currency;
    }
    return null;
  }

  fnLocalValue(x) {
    if (x.isReceived) {
      return "";
    } else if (!x.isReceived) {
      return "Local:" + this._numberFormatPipe.transform(x.CreditLC) + " " + x.BaseCurrency;
    }
    return null;
  }

  fnComment(comment) {
    comment = comment.replace(/(\r\n|\n|\r)/gm, "\u21b5");
    comment = comment.replace('\r\n', '<br/>');
    comment = comment.replace(/\u21B5/g, '<br/>');
    comment = comment.replace(/↵/, '<br/>');
    return comment;
  }
  RowCustomerDetailsInDepth() {

  }
  RowDetailsInDepth(x) {
    if (x.isCash) {
      if (x.DenominationsInOutList != null && x.DenominationsInOutList.length > 0) {
        let z = "<b>Denominations " + (x.isReceived ? "Received" : "Paid") + "</b><br />";
        for (var a in x.DenominationsInOutList) {
          // return JSON.stringify(x.DenominationsInOutList[a]);
          z += "<b>" + this._numberFormatPipe.transform(x.DenominationsInOutList[a]['Denomination']) + "</b>" + " x " + "<b>" + this._numberFormatPipe.transform(x.DenominationsInOutList[a]['Quantity']) + "</b> = <b>" + this._numberFormatPipe.transform(x.DenominationsInOutList[a]['Value']) + "</b><br />";
        }
        return z;
      }
    }
  }


  BranchCodeDisplay(x) {
    if (x) {
      return "(" + x + ")";
    }
    else
      return "";
  }


}



