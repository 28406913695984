import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { share } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { GetUserObject } from "../_helpers/get-user-object";
import { responseModel } from "../_models/response.model";

@Injectable({
  providedIn: "root"
})
export class InvoiceCaluclatorService {
  headers: { headers: any; };

  constructor (private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return (this.headers = httpOptions);
  }

  fnAddInvoiceRow(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-invoice-row", val);
  }

  fnCheckTillLogin(val) {
    return this._http.get<responseModel>(environment.apiUrl + "till-login-check?OwnerID=" + val);
  }
  fnBalanceRow(val) {
    return this._http.post<responseModel>(environment.apiUrl + "balance-invoice-rows", val);
  }
  fnAddEWireRows(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-ewire-rows", val);
  }
  fnInvoiceSubmit(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice-submit", val);
  }
  fnDeleteAttachmentsOfNotCreatedInvoice() {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/delete-attachment-unsaved-invoice", null);
  }
  fnDeleteInvoice(val) {
    return this._http.post<responseModel>(environment.apiUrl + "delete-invoice", val);
  }
  fnDeleteRow(val) {
    return this._http.post<responseModel>(environment.apiUrl + "delete-row", val);
  }
  fnGetInvoiceDetails(val) {
    return this._http.post<responseModel>(environment.apiUrl + "get-invoice-details", val);
  }
  fnAmountReceivedFromCustomer(val) {
    return this._http.post<responseModel>(environment.apiUrl + "amount-received-from-customer-rows", val);
  }
  fnAmountReceivedToAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + "amount-received-to-account-rows", val);
  }
  fnAmountPaidFromAccount(val) {
    return this._http.post<responseModel>(environment.apiUrl + "amount-payed-from-account-rows", val);
  }
  fnGetPictureIds(val) {
    return this._http.post<responseModel>(environment.apiUrl + "get-picture-ids", val);
  }
  fnPickCustomerByID(val) {
    return this._http.post<responseModel>(environment.apiUrl + "pick-customer-by-id", val).pipe(share());
  }
  fnUpsertAttachment(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/upsert-attachment", val);
  }
  fnDeleteAttachment(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/delete-attachment", val);
  }

  fnEmailInvoice(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/email-invoice", val);
  }
  fnGetEmailDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + "v2/invoice/get-emails-by-id", val);
  }

  fnSettleEWireRowsByCash(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-ewire-settle-by-cash-rows", val).pipe(share());
  }
  fnSettleEWireRowsByWire(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-ewire-settle-by-wire-rows", val).pipe(share());
  }
  fnSettleEWireRows(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-ewire-settle-row", val).pipe(share());
  }
  fnAddTransferRows(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-transfer-rows", val);
  }

  fnFetchAccountBalance(val) {
    return this._http.post<responseModel>(environment.apiUrl + "fetch-account-balance", val).pipe(share());
  }

  fnGetPaymentTypeDD() {
    return this._http.post<responseModel>(environment.apiUrl + "get-payment-type-dd", null);
  }
  fnGetCurrencyRatesListDD(val) {
    return this._http.post<responseModel>(environment.apiUrl + "get-currency-rates-list-DD", val);
  }

  fnGetDenominationByCurrency(Denominations) {
    return this._http.post<responseModel>(environment.apiUrl + "get-denomination-by-currency", Denominations);

  }

  fnGetEwireCountries() {
    return this._http.get<responseModel>(`${ environment.apiUrl }get-ewire-countries`, {
      params: new HttpParams().set('OwnerID', GetUserObject.GetOwnerID())
    });
  }

  fnGetAccountsDDByCustomer(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/get-account-dd-by-customer", val);
  }

  fnGetWireAccounysByCustomer(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }invoice/get-wire-account-dd-by-customer`, val);
  }

  fnGetBankAccountsByCustomer(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }invoice/get-bank-account-dd-by-customer`, val);
  }

  fNGetWireTemplateByCustomer(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }generic/get-wire-template-by-customer`, val);
  }

  fnAddSwapRows(val) {
    return this._http.post<responseModel>(environment.apiUrl + "add-swap-rows", val);
  }

  fnGetAutorizeUsers() {
    return this._http.get<responseModel>(environment.apiUrl + "get-authorize-users");
  }

  fnAuthorizeTransaction(val) {
    return this._http.post<responseModel>(environment.apiUrl + "authorize-transaction", val);
  }

  fnAddRowsFromOrder(val, isAccount) {
    let params = new HttpParams();
    params = params.set('orderId', val);
    params = params.set('isAccount', isAccount);
    return this._http.get<responseModel>(`${ environment.apiUrl }add-order-rows`, { params: params });
  }

  fnAddRowsFromBooking(val) {
    let params = new HttpParams();
    params = params.set('bookingId', val);
    return this._http.get<responseModel>(`${ environment.apiUrl }add-booking-rows`, { params: params });
  }

  fnGetFeeRules() {
    return this._http.get<responseModel>(environment.apiUrl + "invoice-get-fee-rules");
  }

  fnUpsertReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/upsert-invoice-report", val);
  }
  fnDeleteReport(val) {
    return this._http.post<responseModel>(environment.apiUrl + "invoice/delete-invoice-report", val);
  }
  fnGetReports(val) {
    let params = new HttpParams();
    params = params.set('InvoiceID', val);
    return this._http.get<responseModel>(environment.apiUrl + "invoice/get-invoice-reports", { params: params });
  }
  fnAddItems(val) {
    return this._http.post<responseModel>(`${ environment.apiUrl }add-item`, val);
  }
}
