
export enum CodeMasterNames {
    Gender = "Gender",
    Salutation = "Salutation",
    GroupType = "Group Type",
    PhoneType = "Phone Type",
    EmailType = "Email Type",
    AddressType = "Address Type",
    SocialHandleType = "Social Handle Type",
    Relationship = "Relationship",
    DocumentType = "Document Type",
    MainAccountType = "Main Account Type",
    PaymentType = "Payment Type",
    MethodofPaymentReceived = "Method of Payment Received",
    DeliveryPaymentMethod = "Delivery Payment Method",
    PurposeofTransaction = "Purpose of Transaction",
    SourceofFunds = "Source of Funds",
    TypeofCheque = "Type of Cheque",
    SanctionListSearchType = "SanctionList Search Type",
    NotesType = "Notes Type",
    BasisofRiskDetermination = "Basis of Risk Determination",
    TypeofBusiness = "Type of Business",
    NatureofBusiness = "Nature of Business",
    TypesofAMLAlerts = "Types of AML Alerts",
    CustomerStatus = "Customer Status",

}

export enum CustomerStatusDD {
    Active = "Active",
    InActive = "Inactive",
    Terminated = "Terminated",
    PendingVerification = "Pending Verification",
    Monitor = "Monitor"
}

export enum TransactionTableNames {
    Account = "AccountTransactions",
    Cheque = "ChequeTransactions",
    Wire = "WireTransactions",
    Cash = "CashTransactions",
    Ewire = "eWireTransactions"
}
export class KeyValueList {
    static list = [
        "disclaimer.invoice.en",
        "disclaimer.invoice.de",
        "disclaimer.invoice.es",
        "disclaimer.invoice.it",
        "disclaimer.invoice.pt",
        "notify.email",
        'clerk.mandatory',
        'invoice.button3.account',
        'invoice.button3.label',
        'invoice.button3.isPay',
        'invoice.button1.isPay',
        'invoice.button2.isPay',
        'invoice.button1.account',
        'invoice.button1.label',
        'invoice.button2.account',
        'invoice.button2.label',
        'invoice.button1.flatFee',
        'invoice.button1.pctFee',
        'invoice.button2.flatFee',
        'invoice.button2.pctFee',
        'invoice.button3.flatFee',
        'invoice.button3.pctFee',
        'invoice.show.attachments',
        'invoice.show.EditCustomer',
        'invoice.show.viewPastTransaction',
        'invoice.show.swap',
        'invoice.show.transfer',
        'adjust-account-balance',
        'spendology.branch',
        'spendology.accountID.card',
        'spendology.notification.email',
        'spendology.accountID.delivery',
        'spendology.accountID.transaction',
        'spendology.accountID.discrepency',
        'spendology.orders.notifyByEmail',
        'spendology.pay.AccountID',
        // WIP - will be moving to credits to apikey 'regula.credits',
        'regula.apiSubscriptionKey',
        'invoice.fees.hide',
        'contacts.individual.entryform',
        'invoice.requestUserAndPin'
    ];
}

export class FontFamily {

    static list = [
        'Courier New',
        'Courier',
        'Franklin Gothic Medium',
        'Arial Narrow', 'Arial',
        'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS',
        'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', 'Geneva', 'Verdana',
        'Segoe UI', 'Tahoma', 'Geneva',
        'Times New Roman', 'Times', 'serif',
        'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans',
        'Helvetica',
        'Cambria', 'Cochin', 'Georgia', 'Times New Roman',
        'Times New Roman',
        'Impact', 'Haettenschweiler', 'Arial Narrow Bold',
        'Verdana',
        'cursive',
        'fantasy',
        'monospace',
        'sans-serif',
        'serif',
        '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue',
        'inherit',
        'initial',
        'unset'
    ];
}

export const PrintOptions = {

    list: [
        { id: 1, value: 'Standard format' },
        { id: 2, value: 'Standard format including Customer details' },
        { id: 3, value: 'Thermal - 5" format' },
        { id: 4, value: 'Thermal with Logo - 5"' },
        { id: 5, value: 'eWire format' },
        { id: 6, value: 'Narrow Print' },
        { id: 7, value: 'Simple format' },
        { id: 8, value: 'Thermal 80mm Receipt' },
        { id: 9, value: 'Mini Receipt' },
        { id: 10, value: '4D Format' }

    ]
};

export enum PrintFormats {
    StandardFormat = 1,
    StandardFormatIncludingCustomerDetails = 2,
    Thermal5InchFormat = 3,
    ThermalWithLogo5Inch = 4,
    EWireFormat = 5,
    NarrowPrint = 6,
    SimpleFormat = 7,
    Thermal80mmReceipt = 8,
    MiniReceipt = 9,
    Format4D = 9,
}


export const DateFormats = {
    list: [
        { id: 1, name: 'DD/MM/YYYY', pickerFormat: "dd/mm/yy", displayFormat: "dd/MM/yyyy" },
        { id: 2, name: 'MM/DD/YYYY', pickerFormat: "mm/dd/yy", displayFormat: "MM/dd/yyyy" },
        { id: 3, name: 'YYYY/MM/DD', pickerFormat: "yy/mm/dd", displayFormat: "yyyy/MM/dd" },
        { id: 4, name: 'YYYY-MM-DD', pickerFormat: "yy-mm-dd", displayFormat: "yyyy-MM-dd" }

    ]
};

export enum keyValueTypes {
    clerk = "clerk.mandatory",
    payAccountID = "spendology.pay.AccountID",
    adjustAccountBalance = "adjust-account-balance",
    regulaCredits = "regula.credits",
    regulaApiSubscriptionKey = 'regula.apiSubscriptionKey',
    disclaimerInvoiceEn = 'disclaimer.invoice.en',
    disclaimerInvoiceDe = 'disclaimer.invoice.de',
    disclaimerInvoiceEs = 'disclaimer.invoice.es',
    disclaimerInvoiceIt = 'disclaimer.invoice.it',
    disclaimerInvoicePt = 'disclaimer.invoice.pt',
    notifyEmail = 'notify.email',
    clerkMandatory = 'clerk.mandatory',
    invoiceButton1Account = 'invoice.button1.account',
    invoiceButton1Label = 'invoice.button1.label',
    invoiceButton2Account = 'invoice.button2.account',
    invoiceButton2Label = 'invoice.button2.label',
    invoiceButton3Account = 'invoice.button3.account',
    invoiceButton3Label = 'invoice.button3.label',
    invoiceButton1FlatFee = 'invoice.button1.flatFee',
    invoiceButton1PctFee = 'invoice.button1.pctFee',
    invoiceButton2FlatFee = 'invoice.button2.flatFee',
    invoiceButton2PctFee = 'invoice.button2.pctFee',
    invoiceButton3FlatFee = 'invoice.button3.flatFee',
    invoiceButton3PctFee = 'invoice.button3.pctFee',
    incoiceButton1isPay = 'invoice.button1.isPay',
    incoiceButton2isPay = 'invoice.button2.isPay',
    incoiceButton3isPay = 'invoice.button3.isPay',
    invoiceShowAttachments = 'invoice.show.attachments',
    invoiceShowEditCustomer = 'invoice.show.EditCustomer',
    invoiceShowViewPastTransaction = 'invoice.show.viewPastTransaction',
    invoiceShowSwap = 'invoice.show.swap',
    invoiceShowTransfer = 'invoice.show.transfer',
    spendologyBranch = 'spendology.branch',
    spendologyAccountIDCard = 'spendology.accountID.card',
    spendologyNotificationEmail = 'spendology.notification.email',
    spendologyAccountIDDelivery = 'spendology.accountID.delivery',
    spendologyAccountIDTransaction = 'spendology.accountID.transaction',
    spendologyAccountIDDiscrepency = 'spendology.accountID.discrepency',
    spendologyOrdersNotifyByEmail = 'spendology.orders.notifyByEmail',
    spendologyPayAccountID = 'spendology.pay.AccountID',
    invoiceFeesHide = 'invoice.fees.hide',
    contactsIndividualEntryform = 'contacts.individual.entryform',
    invoiceRequestUserAndPin = 'invoice.requestUserAndPin'
};

export const NumberFormats = [
    { id: 1, name: '1,000.00' },
    { id: 2, name: '1`000,00' },
    { id: 3, name: '1 000,00' },
];

