import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap';
import { NgxCaptchaModule } from 'ngx-captcha';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CxrCrmSharedModule } from 'projects/cxr-crm/src/app/app.module';
import { translationUrl } from 'projects/cxr-crm/src/app/translationurl';
import { CXRCustomerSharedModule } from 'projects/cxr-customer/src/app/app.module';
import { CXRSuperAdminSharedModule } from 'projects/cxr-super-admin/src/app/app.module';
import { CXRCustomerSupportModule } from '../../projects/cxr-customer-support/src/app/app.module';
import { OwnerLoginGuard } from './_guards/owner-login.guard';
import { SuperOwnerLoginGuard } from './_guards/super-owner-login.guard';
import { SupportingStaffLoginLoginGuard } from './_guards/supporting-staff-guard';
import { ErrorInterceptor } from './_helpers';
import { TableNames } from './_helpers/app.tablenames';
import { JwtInterceptor } from './_helpers/jwt-interceptor';
import { LoaderService } from './_services/loader.service';
import { LoginService } from './_services/login.service';
import { WindowRef } from './_services/window-ref.service';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { CurrencyRateBoardComponent } from './pages/currency-rate-board/currency-rate-board.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { PrintInvoicePreviewComponent } from './pages/print-invoice-preview/print-invoice-preview.component';
import { TellerApprovalComponent } from './pages/teller-approval/teller-approval.component';
import { SharedDialogModule } from './shared-dialog/shared-dialog.module';
import { SharedModule } from './shared/shared.module';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { PipesModule } from './theme/pipes/pipes.module';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true
};

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, translationUrl, '');
}


const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'crm', canActivate: [OwnerLoginGuard], canActivateChild: [OwnerLoginGuard], loadChildren: () => import('../../projects/cxr-crm/src/app/app.module').then(m => m.CxrCrmSharedModule) },
    { path: 'super-admin', canActivate: [SuperOwnerLoginGuard], canActivateChild: [SuperOwnerLoginGuard], loadChildren: () => import('../../projects/cxr-super-admin/src/app/app.module').then(m => m.CXRSuperAdminSharedModule) },
    { path: 'customer', loadChildren: () => import('../../projects/cxr-customer/src/app/app.module').then(m => m.CXRCustomerSharedModule) },
    { path: 'customer-support', canActivate: [SupportingStaffLoginLoginGuard], canActivateChild: [SupportingStaffLoginLoginGuard], loadChildren: () => import('../../projects/cxr-customer-support/src/app/app.module').then(m => m.CXRCustomerSupportModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error', description: "Dashboard Screen" } },
    { path: 'invoice-print/:InvoiceID', canActivate: [OwnerLoginGuard], component: PrintInvoicePreviewComponent, data: { breadcrumb: '', description: "" } },
    { path: 'currency-rate-board', component: CurrencyRateBoardComponent, data: { description: "Currency Rate Board Screen" } },
    { path: 'teller-payment-approval/:key', component: TellerApprovalComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [


        FlexLayoutModule,
        NgxCaptchaModule,
        ModalModule,
        TranslateModule,



        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        MatButtonModule,


        // FormsModule,
        // ReactiveFormsModule,
        // AgmCoreModule.forRoot({
        //   apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
        // }),
        PerfectScrollbarModule,
        // CalendarModule.forRoot(),
        SharedModule,
        FlexLayoutModule,
        SharedDialogModule,

        MatProgressSpinnerModule,
        MatIconModule,
        MatFormFieldModule,
        NgxCaptchaModule,
        MatTooltipModule,
        MatCardModule,
        MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatGridListModule,
        MatTabsModule,
        MatProgressBarModule,
        PipesModule,
        RouterModule.forRoot(routes, {
            //enableTracing: true,
            //preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
            useHash: false
        }),
        CxrCrmSharedModule.forRoot(),
        CXRSuperAdminSharedModule.forRoot(),
        CXRCustomerSharedModule.forRoot(),
        CXRCustomerSupportModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true
        }),
        // HttpClientXsrfModule.withOptions({
        //   cookieName: "X-XSRF-Token",
        //   headerName: "X-XSRF-Token"
        // })
        // NgHttpLoaderModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        NotFoundComponent,
        ErrorComponent,
        SidenavComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        BreadcrumbComponent,
        FlagsMenuComponent,
        FullScreenComponent,
        ApplicationsComponent,
        MessagesComponent,
        UserMenuComponent,
        PrintInvoicePreviewComponent,
        CurrencyRateBoardComponent,
        TellerApprovalComponent,

    ],
    exports: [
        NotFoundComponent,
        ErrorComponent
    ],
    providers: [
        AppSettings, TableNames, LoginService, LoaderService, WindowRef,
        OwnerLoginGuard, SuperOwnerLoginGuard, SupportingStaffLoginLoginGuard,
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        { provide: OverlayContainer, useClass: CustomOverlayContainer }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
